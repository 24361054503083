import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { Pagination, PaginationItem, PaginationLink, FormGroup, Input } from "reactstrap";
import { CircularProgress } from '@material-ui/core';
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";

const ReactTable = ({ columns, ExtraStuff, data, isLoading = false, hasHandleShow, handleShowDetails = () => { }, footer = false, disabledGlobalSearch, className }) => {
	const [dataArray, setDataArray] = useState(data);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		gotoPage,
		setPageSize,
	} = useTable(
		{
			columns,
			data: dataArray,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const setColumnFilter = (column, searchValue) => {
		const filteredItems = data.filter(item => {
			let columnValue;
			let columnWithValue;
			if (column.id.includes('.')) {
				columnValue = getColumnValue(item, column.id);
			} else if (column?.with) {
				columnValue = String(item[column.id]).toLowerCase();
				columnWithValue = String(item[column.with]).toLowerCase();
			} else {
				columnValue = String(item[column.id]).toLowerCase();
			}

			if (columnValue && typeof columnValue === 'string' && columnWithValue && typeof columnWithValue === 'string') {
				return columnValue.toLowerCase().includes(searchValue.toLowerCase()) || columnWithValue.toLowerCase().includes(searchValue.toLowerCase());
			} else if (columnValue && typeof columnValue === 'string') {
				return columnValue.toLowerCase().includes(searchValue.toLowerCase());
			}
			return false;
		});

		if (filteredItems.length > 0) {
			setDataArray(filteredItems);
		} else {
			setDataArray([]);
		}
	};

	const getColumnValue = (item, columnId) => {
		const keys = columnId.split('.');
		let value = item;
		for (const key of keys) {
			if (value && value.hasOwnProperty(key)) {
				value = value[key];
			} else {
				return undefined;
			}
		}
		return value;
	};

	const { globalFilter, pageIndex, pageSize } = state;

	let columnTotals;
	if (footer) {
		columnTotals = columns.map(column => {
			if (column.Header === "Product Line") {
				return "Total Commission";
			} else if (column.accessor && !isNaN(Number(page[0]?.values[column.accessor]))) {
				const total = dataArray?.reduce((acc, row) => {
					const data = row[column?.quantitySchema][column?.quantityAccessorInd]
					const cellValue = data['Commission'] + data['SupplementaryCommission']
					return acc + cellValue;
				}, 0);
				return <span style={total > 0 ? { color: "green", fontSize: "medium" } : { color: "red", fontSize: "medium" }}>${total.toFixed(2)}</span>;
			}
			return null;
		});
	}

	const arrayPageIndex =
		pageIndex - 2 < 0
			? pageOptions.slice(0, pageIndex + 3)
			: pageOptions.slice(pageIndex - 2, pageIndex + 3);

	useEffect(() => {
		setDataArray(data)
	}, [data])

	return (
		<div>
			<Row>
				<Col lg={4} md={12} className='mt-2'>
					{!disabledGlobalSearch && <Input
						type="text"
						value={globalFilter || ''}
						onChange={(e) => setGlobalFilter(e.target.value)}
						placeholder="Search..."
						className=''
					/>}
				</Col>
				<Col lg={8} md={12} className='d-flex justify-content-lg-end justify-content-md-center mt-lg-0 mt-2 align-items-center'>
					{ExtraStuff && <ExtraStuff />}
				</Col>
			</Row>
			{isLoading ? (
				<div className="d-flex justify-content-center my-2">
					<CircularProgress />
				</div>
			) : (
				<Table responsive {...getTableProps()} style={{ marginTop: '20px' }} className={className}>
					<thead>
						{headerGroups.map((headerGroup, i) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={i}>
								{headerGroup.headers.map((column, index) => (
									<th key={index} {...column.getHeaderProps({ style: { width: column.width } })}>
										<div {...column.getHeaderProps(column.getSortByToggleProps())}>
											{column.render('Header')}
											<span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
										</div>
										{column?.filter === false ? <div style={{ height: "40px" }}></div> : (
											<Input
												type="text"
												onChange={(e) => setColumnFilter(column, e.target.value)}
												placeholder={`Search ${column.render('Header')} `}
												className=''
											/>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					{isLoading ? "Loading" : dataArray?.length === 0 ? <div>No data found.</div> :
						<tbody {...getTableBodyProps()}>
							{page.map((row) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()} onClick={() => handleShowDetails(row.original)} style={{ cursor: `${hasHandleShow ? `pointer` : ""}` }}>
										{row.cells.map((cell, index) => {
											return (
												<td {...cell.getCellProps()}>
													<span> {cell.render('Cell')} {cell.column.with ? `- ${row.original[`${cell.column.with}`]}` : ""}</span>
												</td>
											)
										})}
									</tr>
								);
							})}
						</tbody>}
					{footer ?
						dataArray?.length ? <tfoot>
							<tr>
								{columns.map((column, columnIndex) => (
									<td key={columnIndex}>
										{columnTotals[columnIndex]}
									</td>
								))}
							</tr>
						</tfoot> : ""
						: ""}
				</Table>
			)}
			<Pagination className="pagination" dir="ltr">
				<div className="pagination">
					<PaginationLink
						className="pagination__link pagination__link--arrow"
						type="button"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						<ChevronDoubleLeftIcon className="pagination__link-icon" />
					</PaginationLink>
					<PaginationLink
						className="pagination__link pagination__link--arrow"
						type="button"
						onClick={previousPage}
						disabled={!canPreviousPage}
					>
						<ChevronLeftIcon className="pagination__link-icon" />
					</PaginationLink>
					{isLoading ? <div className="d-flex justify-content-center "><CircularProgress size={"20px"} /></div> : arrayPageIndex.map((i) => (
						<PaginationItem
							className="pagination__item"
							active={pageIndex === i}
							key={i}
						>
							<PaginationLink
								key={i}
								className="pagination__link"
								type="button"
								onClick={() => gotoPage(i)}
							>
								{i + 1}
							</PaginationLink>
						</PaginationItem>
					))}
					<PaginationItem className="pagination__item">
						<PaginationLink
							className="pagination__link pagination__link--arrow"
							type="button"
							onClick={nextPage}
							disabled={!canNextPage}
						>
							<ChevronRightIcon className="pagination__link-icon" />
						</PaginationLink>
					</PaginationItem>
					<PaginationItem className="pagination__item">
						<PaginationLink
							className="pagination__link pagination__link--arrow"
							type="button"
							onClick={() => gotoPage(pageOptions.length - 1)}
							disabled={!canNextPage}
						>
							<ChevronDoubleRightIcon className="pagination__link-icon" />
						</PaginationLink>
					</PaginationItem>
					{isLoading ? '' : <PaginationItem className="pagination__item pagination-info">
						Showing {pageSize * pageIndex + 1} to{" "}
						{pageSize * pageIndex + page.length} of {pageOptions.length}
					</PaginationItem>}
					{[10, 20, 30, 40, 50].length > 1 && (
						<PaginationItem className="pagination__item">
							<FormGroup className="pagination__select-form ">
								<Input
									className="pagination__item pagination-info"
									type="select"
									name="select"
									id="exampleSelect"
									value={pageSize}
									onChange={(event) => {
										setPageSize(Number(event.target.value));
									}}
								>
									{[10, 20, 30, 40, 50].map((item) => (
										<option
											className="pagination__item pagination__item-option"
											key={item}
											value={item}
										>
											Show {item}
										</option>
									))}
								</Input>
							</FormGroup>
						</PaginationItem>
					)}
				</div>
			</Pagination>
		</div>
	);
};

export default ReactTable;
