import { useEffect, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { useLiveQuery } from "dexie-react-hooks";
import { Formik } from "formik";
import * as Yup from "yup";
import { db } from "./db";
import { errorToast, successToast } from "../../../Element/toast";
import noImage from "../../../../images/noImage.png"
import Modal from "../../../components/Modal";
import CustomInput from "../../../../shared/components/form/CustomInput";

const AddProductForm = ({ editDetails, setEditDetails, productQuantityArray }) => {
	const [scanProduct, setScanProduct] = useState({});
	const [isProductOptions, setIsProductOptions] = useState([]);
	const [isProductExist, setIsProductExist] = useState({});
	const [isProductExistorNot, setIsProductExistorNot] = useState(false);
	const [quantity, setQuantity] = useState();
	const validationSchema = Yup.object().shape({
		Products: Yup.mixed().required("Please select or scan product."),
		Quantity: Yup.string().required("Please enter qauntity."),
	});

	const allProducts = useLiveQuery(
		async () => {
			const Prod = await db.Products.toArray() || [];
			return Prod;
		}
	);

	const onSubmit = async (values, { resetForm }) => {
		setScanProduct(values.Products);
		setQuantity(values.Quantity);
		const isProductExists = allProducts?.find((product) => product?.name === values.Products?.value);
		if (isProductExists && Object.entries(isProductExists)?.length > 0) {
			setQuantity(parseInt(isProductExists.quantity))
			setIsProductExistorNot(true)
			setIsProductExist(isProductExists);
			return
		}
		try {
			await db.Products.add({ variantid: values.Products?.varinatId, name: values.Products?.value, quantity: parseInt(values.Quantity), other: values.Products?.other });
			successToast(`Product successfully added`);
			resetForm()
			clearForm()
		} catch (error) {
			errorToast(`Failed to add: ${error}`);
		}
	}

	const updateQuantity = async () => {
		try {
			await db.Products.update(isProductExist?.id, { name: isProductExist?.name, quantity: parseInt(quantity) });
			successToast(`Product quantity updated successfully`);
			setQuantity()
			clearForm()
			setIsProductExistorNot(!isProductExistorNot)
		} catch (error) {
			errorToast(`Failed to update: ${error}`);
		}
	}

	const clearForm = () => {
		setEditDetails({});
		setScanProduct('');
		setQuantity("");
	}

	useEffect(() => {
		if (productQuantityArray && productQuantityArray?.length) {
			let a = productQuantityArray.map((prod) => {
				return {
					id: prod?.id,
					productImage: prod?.productImage,
					inventoryQuantity: parseInt(prod?.inventoryQuantity),
					varinatId: prod?.varinatId,
					label: prod?.productTitle + " - " + prod?.variantTitle,
					value: prod?.productTitle + " - " + prod?.variantTitle,
					other: JSON.stringify(prod)
				}
			})
			setIsProductOptions(a)
		}
	}, [productQuantityArray])

	return (
		<>
			<Formik
				initialValues={{
					Products: "",
					Quantity: ""
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => {
					const {
						errors,
						touched,
						values,
						handleSubmit,
						handleBlur,
						handleChange,
						setFieldValue,
						resetForm
					} = formik;
					return (
						<>
							<Row className="mt-lg-3">
								<Col md={7}>
									{isProductOptions?.length &&
										<div>
											<Label for="Products">
												Select product
												<font color="red"> *</font>
											</Label>
											<Select
												className={`lazySelect`}
												name="Products"
												value={values.Products}
												options={isProductOptions}
												getOptionValue={(option) => option.value}
												getOptionLabel={(option) => {
													if (option.id) {
														return (<div className="d-flex justify-content-between">
															<div>
																<img src={option?.productImage ? option?.productImage : noImage} style={{ width: "50px", marginRight: "10px" }} alt="" />
																{option.label.length > 50 ? option.label.slice(0, 50) + '...' : option.label}
															</div>
															<div className="d-flex align-items-center">
																{option.inventoryQuantity}
															</div>
														</div>)
													}
												}}
												placeholder={`Select product`}
												onBlur={handleBlur}
												onChange={(e) => {
													setFieldValue("Products", e)
													if (!values.Quantity) setFieldValue("Quantity", 1)
												}}
											/>
											{touched.Products && errors.Products && <font color="red">{errors.Products}</font>}
										</div>}
								</Col>
								<Col md={5}>
									<CustomInput
										label="Product Quantity"
										name="Quantity"
										type="number"
										fieldErrors={errors.Quantity}
										fieldTouched={touched.Quantity}
										fieldValue={values.Quantity}
										handleBlur={handleBlur}
										handleChange={handleChange}
										required
									/>
								</Col>
							</Row>

							<div className={`d-flex justify-content-center mt-3 `}>
								<Button
									color="primary"
									onClick={handleSubmit}
									value="submit"
								>Add Product
								</Button>
							</div>
							{isProductExistorNot && (
								<Modal
									color="warning"
									title="Warning"
									message={
										<div className="p-3">
											<p color='orange'>Product "<apan className={"font-weight-bold"}>{isProductExist?.name}</apan>" already exist. Do you want to add the quantity to the existing product?</p>
											<div className="d-flex justify-content-between">
												<Input type='number' value={quantity} onChange={(e) => setQuantity(e.target.value)} />
											</div>
										</div>
									}
									handleOkClick={() => {
										updateQuantity()
										resetForm()
									}}
									toggle={() => setIsProductExistorNot(!isProductExistorNot)}
								/>
							)}
						</>
					)
				}}
			</Formik>
		</>
	);
}

export default AddProductForm