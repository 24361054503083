import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Col, Row, Table } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

const SalesDetails = (props) => {
	const data = props?.location?.state?.data;
	const history = useHistory()

	const [chartData, setChartData] = useState({
		series: [
			{
				name: "Net Quantity",
				data: data?.VariantQuantityDTOs.map(item => item.NetQuantity)
			},
			{
				name: "Supplementary Quantity",
				data: data?.SupplementarySalesDTOs.map(item => item.Quantity)
			}
		],
		options: {
			chart: {
				height: 350,
				group: 'social',
				type: 'line',
				zoom: {
					enabled: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			markers: {
				size: 5
			},
			grid: {
				row: {
					colors: ['#f3f3f3', 'transparent'],
					opacity: 0.5
				}
			},
			xaxis: {
				categories: data?.VariantQuantityDTOs.map(item => moment(item.CreatedAt).format('MMM DD, YYYY')),
			}
		}
	});

	return data ? (
		<>
			<div className='py-3'>
				<div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
					<div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
						<div className="card__title"><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i> Product</h5></div>
						<Row>
							<Col lg={6} className="sales-details-container">
								<Table bordered>
									<tbody>
										<tr>
											<td className='sales-details-text'><b>Product Title:</b></td>
											<td className='sales-details-text'>{data?.ProductTitle ?? "-"}</td>
										</tr>
										<tr>
											<td className='sales-details-text'><b>Product Vendor:</b></td>
											<td className='sales-details-text'>{data?.ProductVendor ?? "-"}</td>
										</tr>
										<tr>
											<td className='sales-details-text'><b>Variant Title:</b></td>
											<td className='sales-details-text'>{data?.VariantTitle?.length ? data.VariantTitle : "-"}</td>
										</tr>
										<tr>
											<td className='sales-details-text'><b>Variant SKU:</b></td>
											<td className='sales-details-text'>{data?.VariantSKU ?? "-"}</td>
										</tr>
										<tr>
											<td className='sales-details-text'><b>Net Sale:</b></td>
											<td className='sales-details-text'>${(data?.NetSales + data?.TotalSupplementaryNetSale).toFixed(2) ?? "-"}</td>
										</tr>
									</tbody>
								</Table>
							</Col>
							<Col lg={6} className="dashboard__chart-container">
								<div id="chart">
									<ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
								</div>
								<div id="html-dist"></div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	) : <div className="d-flex justify-content-center "><CircularProgress /></div>;
}

export default SalesDetails
