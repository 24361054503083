import React, { useState } from "react";
import { Button, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { useUpdateMutation } from "../../../services/pcApi";
import { USER_TAG } from "../../../shared/tagFiles";
import { CircularProgress } from "@material-ui/core";
import { successToast } from "../../Element/toast";

const EditProfileModal = ({ setIsAddModalOpen, isAddModalOpen, editData }) => {
  const user = useSelector(state => state?.data?.user);
  const [userDetail, setUserDetail] = useState({ ...editData, PhoneNumber: editData?.ContactDTO?.[0]?.PhoneNumber });
  const [profileImage, setProfileImage] = useState(null);
  const [updateUserDetails, { isLoading: isUserUpdateLoading }] = useUpdateMutation();

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (Object.keys(userDetail)?.length) {
      let formData = new FormData()
      formData.append('Id', userDetail?.Id)
      formData.append('UserTypeId', userDetail.UserTypeId)
      formData.append('EntityId', userDetail.EntityId)
      formData.append('ContactTypeId', userDetail.ContactTypeId || 1)
      formData.append('IsMicrosoftLogin', userDetail?.IsMicrosoftLogin)
      formData.append('IsGoogleLogin', userDetail?.IsGoogleLogin)
      formData.append('Name', userDetail?.Name)
      formData.append('PhoneNumber', userDetail?.PhoneNumber)
      formData.append('SsoIdentifier', userDetail?.SsoIdentifier)
      formData.append('IsAppleLogin', userDetail?.IsAppleLogin)
      formData.append('IsFacebookLogin', userDetail?.IsFacebookLogin)
      formData.append('Email', userDetail?.Email)
      formData.append('Description', userDetail?.Description)

      if (profileImage) {
        formData.append('File', profileImage)
      }

      updateUserDetails({
        entity: `Auth/User/${user?.id}?id=${user?.id}`,
        data: formData,
        tag: USER_TAG,
      }).then((response) => {
        if (!response.error) {
          setIsAddModalOpen(!isAddModalOpen);
          successToast("Profile updated")
        }
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <h3>Profile Settings</h3>
      </div>
      <div className="profile-main-div">
        <div>
          <div style={{ position: "relative" }}>
            {editData?.id || (
              <div className="">
                <label htmlFor="frontInputPhoto">
                  <input
                    type="file"
                    name="frontInputPhoto"
                    id="frontInputPhoto"
                    files={profileImage || ""}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => setProfileImage(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                  {(profileImage || editData?.UserMediaDTO?.length > 0) ? <img
                    src={profileImage ? URL.createObjectURL(profileImage) : editData?.UserMediaDTO?.[0]?.MediaDTO?.Url}
                    alt=""
                    className="card-img"
                  /> :
                    <div className="card-img profile-image profile-image-letter">
                    </div>}
                  <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="profile-field-div">
          {!editData?.Id ? (
            <SpinnerCircular
              color="white"
              thickness={100}
              secondaryColor="black"
            />
          ) : (
            <form
              onSubmit={(e) => handleUpdateProfile(e)}
              className="ml-md-5 mr-md-4"
            >
              <Row className="d-flex flex-column mt-4">
                <div
                  className="d-flex mb-2 align-items-center "
                  style={{ gap: "5px" }}
                >
                  <Label lg={4}>Name: </Label>{" "}
                  <Input
                    className="edit-profile"
                    type="text"
                    value={userDetail?.Name}
                    onChange={(e) =>
                      setUserDetail({ ...userDetail, Name: e.target.value })
                    }
                    required
                  />
                </div>
                <div
                  className="d-flex mb-2 align-items-center "
                  style={{ gap: "5px" }}
                >
                  <Label lg={4}>Mobile: </Label>{" "}
                  <Input
                    className="edit-profile"
                    type="number"
                    value={userDetail.PhoneNumber}
                    onChange={(e) =>
                      setUserDetail({
                        ...userDetail,
                        PhoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  className="d-flex mb-2 align-items-center "
                  style={{ gap: "5px" }}
                >
                  <Label lg={4}>Email: </Label>{" "}
                  <Input
                    className="edit-profile"
                    type="text"
                    value={editData?.Email}
                    readOnly
                  />
                </div>
                <div
                  className="d-flex mb-2 align-items-center "
                  style={{ gap: "5px" }}
                >
                  <Label lg={4}>Description: </Label>{" "}
                  <Input
                    className="edit-profile"
                    type="text"
                    value={userDetail?.Description}
                    onChange={(e) =>
                      setUserDetail({
                        ...userDetail,
                        Description: e.target.value,
                      })
                    }
                  />
                </div>
              </Row>
              <Row className="mt-4 float-right">
                <Button
                  type="submit"
                  className="site-button"
                  disabled={isUserUpdateLoading}
                >
                  {isUserUpdateLoading ? <CircularProgress size={15} color="white" /> : ""}
                  {" "}
                  Update{" "}
                </Button>
              </Row>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EditProfileModal;
