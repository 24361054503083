import React, { useState, useEffect } from 'react'
import { useLiveQuery } from 'dexie-react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'universal-cookie';
import { useLazyGetSimpleRecordsQuery } from '../../services/pcApi';
import { SHOPIFY_LOCATIONS, SHOPIFY_PRODUCT } from '../../shared/tagFiles';
import { db } from './ProductScan/Dexie/db';
import Content from '../components/Home/Content';
import { useDispatch } from 'react-redux';
import { isProductLoading } from '../../redux/reducers/Slice';

const Home = () => {
    const { isAuthenticated } = useAuth0()
    const [data, setData] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [pageInfo, setPageInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const [getAllShopifyLocationRecords] = useLazyGetSimpleRecordsQuery();
    const [getAllShopifyProductRecords] = useLazyGetSimpleRecordsQuery();
    let cookies = new Cookies()
    let accessToken = cookies.get("accessToken");

    const locations = useLiveQuery(
        async () => {
            const Locations = await db.Locations.toArray();
            return Locations;
        }
    );

    const products = useLiveQuery(
        async () => {
            const AllShopifyProducts = await db.AllShopifyProducts.toArray();
            return AllShopifyProducts;
        }
    );

    const fetchProducts = (entity) => {
        setLoading(true);
        if (isAuthenticated && accessToken?.length) {
            return getAllShopifyProductRecords({
                entity: entity,
                tag: SHOPIFY_PRODUCT,
            })
        }
    };

    useEffect(() => {
        dispatch(isProductLoading(loading))
    }, [loading])

    useEffect(() => {
        if (isAuthenticated && accessToken?.length && locations?.length === 0) {
            getAllShopifyLocationRecords({
                entity: `Shopify/GetLocationDetailFromShopify`,
                tag: SHOPIFY_LOCATIONS,
            }).then((response) => {
                if (!response.error) {
                    db.Locations.clear()
                    let time = new Date()
                    response?.data?.items?.forEach((location) => {
                        db.Locations.add({ id: location.id, active: location.active, name: location.name, address1: location.address1, address2: location.address2, zip: location.zip, city: location.city, province: location.province, country: location.countryName, phone: location.phone, legacy: location.legacy, lastSynced: time });
                    })
                }
            })
        }
    }, [locations, isAuthenticated, accessToken]);

    useEffect(() => {
        if (isAuthenticated && accessToken?.length && products?.length === 0) {
            setData([])
            setAllProducts([])
            setLoading(true);
            const fetchData = () => {
                let entity = `Shopify/GetProductDetailFromShopify`
                fetchProducts(entity).then(({ data }) => {
                    setData(data?.items);
                    setPageInfo(data);
                })
            };
            fetchData();
        }
    }, [isAuthenticated, accessToken, products]);

    useEffect(() => {
        const fetchNextPage = () => {
            let entity = `Shopify/GetProductDetailFromShopify?pageInfo=${pageInfo?.linkHeader?.nextLink.pageInfo}`
            fetchProducts(entity)
                .then(({ data }) => {
                    setAllProducts([...allProducts, ...data.items]);
                    setPageInfo(data);
                });
        };

        if (pageInfo && pageInfo?.hasNextPage) {
            fetchNextPage();
        } else if (pageInfo && !pageInfo?.hasNextPage) {
            setLoading(false)
        }
    }, [pageInfo]);

    useEffect(() => {
        if (isAuthenticated) {
            if (pageInfo != null && !pageInfo?.hasNextPage) {
                setAllProducts([...data, ...allProducts])
                let newAllProduct = [...data, ...allProducts]
                let time = new Date()
                db.AllShopifyProducts.clear();
                newAllProduct?.forEach((item, index) => {
                    item.variants.forEach(variant => {
                        db.AllShopifyProducts.add({
                            Id: index + 1,
                            varinatId: variant?.id,
                            productImage: item?.images?.length ? item.images[0]?.src : '',
                            productId: variant?.productId,
                            productTitle: item?.title,
                            productVendor: item?.vendor,
                            productStatus: item?.status,
                            inventoryItemId: variant?.inventoryItemId,
                            variantTitle: variant?.title,
                            variantPrice: variant?.price?.toString(),
                            variantSku: variant?.sku,
                            inventoryQuantity: variant?.inventoryQuantity,
                            inventoryTrackable: variant?.inventoryManagement == "shopify" ? true : false,
                            barcode: variant?.barcode ?? 0,
                            weight: variant.weight,
                            weightUnit: variant.weightUnit,
                            grams: variant.grams,
                            lastSynced: time,
                        })
                    })
                })
            }
        }
    }, [pageInfo, isAuthenticated])

    return (
        <>
            <div className="page-content bg-white rubik-font">
                <Content isProductLoading={loading} />
            </div >
        </>
    )
}

export default Home;