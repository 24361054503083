import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="full-section">
        <div className="dlab-bnr-inr overlay-primary-dark contact-page">
          <div className="container">
            <div className="section-full col-xs-8">
              <div className="inquiry-form dzForm d-flex justify-content-center align-content-center consultation-form">
                <div className="container">
                  <div className="section-head text-black text-center">
                    <h4 className="text-gray-dark m-b10">Privacy & Policy</h4>
                    <h2 className="box-title m-tb0">
                      Interpretation and Definitions
                      <span className="bg-primary"></span>
                    </h2>
                  </div>
                  <div>
                    <h4 className="box-title m-tb0">Interpretation:-</h4>
                    <p>
                      The words of which the initial letter is capitalized have
                      meanings defined under the following conditions. The following
                      definitions shall have the same meaning regardless of whether
                      they appear in singular or in plural.
                    </p>
                  </div>
                  <div className="defination">
                    <h4 className="box-title m-tb0">Definitions:-</h4>
                    <div className="box-title m-tb0">
                      For the purposes of this Privacy Policy:
                    </div>
                    <ul>
                      <li>
                        <b>Account </b>
                        means a unique account created for You to access our Service
                        or parts of our Service.
                      </li>
                      <li>
                        <b>Affiliate </b>
                        means an entity that controls, is controlled by or is under
                        common control with a party, where "control" means ownership
                        of 50% or more of the shares, equity interest or other
                        securities entitled to vote for election of directors or
                        other managing authority.
                      </li>
                      <li>
                        <b>Application </b>means the software program provided by
                        the Company downloaded by You on any electronic device,
                        named Tabletop Fanatics LLC.
                      </li>
                      <li>
                        <b>Business</b>, for the purpose of the CCPA (California
                        Consumer Privacy Act), refers to the Company as the legal
                        entity that collects Consumers' personal information and
                        determines the purposes and means of the processing of
                        Consumers' personal information, or on behalf of which such
                        information is collected and that alone, or jointly with
                        others, determines the purposes and means of the processing
                        of consumers' personal information, that does business in
                        the State of California.
                      </li>
                      <li>
                        <b>Company </b>(referred to as either "the Company", "We",
                        "Us" or "Our" in this Agreement) refers to Tabletop Fanatics LLC.
                      </li>
                      <li>
                        <b>Consumer</b>, for the purpose of the CCPA (California
                        Consumer Privacy Act), means a natural person who is a
                        California resident. A resident, as defined in the law,
                        includes (1) every individual who is in the USA for other
                        than a temporary or transitory purpose, and (2) every
                        individual who is domiciled in the USA who is outside the
                        USA for a temporary or transitory purpose.
                      </li>
                      <li>
                        <b>Country </b>refers to: Georgia, United States
                      </li>
                      <li>
                        <b>Device </b>means any device that can access the Service
                        such as a computer, a cellphone or a digital tablet.
                      </li>
                      <li>
                        <b>Do Not Track </b>(DNT) is a concept that has been
                        promoted by US regulatory authorities, in particular the
                        U.S. Federal Trade Commission (FTC), for the Internet
                        industry to develop and implement a mechanism for allowing
                        internet users to control the tracking of their online
                        activities across websites.
                      </li>
                      <li>
                        <b>Personal Data </b>is any information that relates to an
                        identified or identifiable individual.
                      </li>
                      <li>
                        For the purposes of the CCPA, Personal Data means any
                        information that identifies, relates to, describes or is
                        capable of being associated with, or could reasonably be
                        linked, directly or indirectly, with You.
                      </li>
                      <li>
                        <b>Sale</b>, for the purpose of the CCPA (California
                        Consumer Privacy Act), means selling, renting, releasing,
                        disclosing, disseminating, making available, transferring,
                        or otherwise communicating orally, in writing, or by
                        electronic or other means, a Consumer's personal information
                        to another business or a third party for monetary or other
                        valuable consideration.
                      </li>
                      <li>
                        <b>Service Provider </b>means any natural or legal person
                        who processes the data on behalf of the Company. It refers
                        to third-party companies or individuals employed by the
                        Company to facilitate the Service, to provide the Service on
                        behalf of the Company, to perform services related to the
                        Service or to assist the Company in analyzing how the
                        Service is used.
                      </li>
                      <li>
                        <b>Third-party Social Media Service </b>refers to any
                        website or any social network website through which a User
                        can log in or create an account to use the Service.
                      </li>
                      <li>
                        <b>Usage Data</b>Data refers to data collected
                        automatically, either generated by the use of the Service or
                        from the Service infrastructure itself (for example, the
                        duration of a page visit).
                      </li>
                      <li>
                        <b>You </b>means the individual accessing or using the
                        Service, or the company, or other legal entity on behalf of
                        which such individual is accessing or using the Service, as
                        applicable.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
