import React, { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { Label } from "reactstrap";
import { Button, Col, Row } from "react-bootstrap";
import RenderData from "./Dexie/RenderData";
import { db } from "./Dexie/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect } from "react";
import { errorToast, successToast } from "../../Element/toast";
import { SHOPIFY_QUANTITY_LOCATIONS } from "../../../shared/tagFiles";
import { useLazyGetRecordsQuery } from "../../../services/pcApi";
import { CircularProgress } from "@material-ui/core";

const ProductScan = ({ next, PreviousButton, currentStep, scanOrigin }) => {
    const [scan, setScanned] = useState(false);
    const [isScanned, setIsScanned] = useState(false);
    const [productQuantityFromShopifyLocation, setProductQuantityFromShopifyLocation] = useState([]);
    const [productQuantityArray, setProductQuantityArray] = useState([]);
    const [isProductQuantityLoading, setIsProductQuantityLoading] = useState(true);
    const [timeoutCounter, setTimeoutCounter] = useState(2);
    const [getAllProductQuantityFromShopifyLocation] = useLazyGetRecordsQuery();
    const allProducts = useLiveQuery(
        async () => {
            const Products = await db.Products.toArray() || [];
            return Products;
        }
    );

    const products = useLiveQuery(
        async () => {
            const AllShopifyProducts = await db.AllShopifyProducts.toArray();
            return AllShopifyProducts;
        }
    );

    const addProduct = async (url) => {
        const getInverntoryItemIdFromQrCode = (url) => url.substring(url.lastIndexOf("/") + 1)
        const isProductScanned = productQuantityArray?.filter((product) => product?.variantSku === getInverntoryItemIdFromQrCode(url) || product?.barcode === getInverntoryItemIdFromQrCode(url))?.[0];
        const isProductExist = allProducts?.filter((product) => JSON.parse(product?.other)?.variantSku === getInverntoryItemIdFromQrCode(url) || JSON.parse(product?.other)?.barcode === getInverntoryItemIdFromQrCode(url))
        if (isProductExist && !isProductExist?.length) {
            if (isProductScanned) {
                try {
                    await db.Products.add({ variantid: isProductScanned?.varinatId, name: isProductScanned?.productTitle, quantity: 1, other: JSON.stringify(isProductScanned) });
                    successToast(`Product successfully added`);

                } catch (error) {
                    errorToast(`Failed to add: ${error}`);
                }
            }
        } else {
            try {
                await db.Products.update(isProductExist?.[0]?.id, { quantity: parseInt(isProductExist?.[0]?.quantity) + 1 });
                successToast(`Product ${isProductExist?.length && isProductExist?.[0]?.name} successfully updated.`);
            } catch (error) {
                errorToast(`Failed to update: ${error}`);
            }
        }
    }

    useEffect(() => {
        if (scanOrigin && Object.entries(scanOrigin)?.length > 0) {
            getAllProductQuantityFromShopifyLocation({
                entity: `Shopify/GetProductQuantityFromShopifyLocation?locationIds=${scanOrigin?.id}`,
                tag: SHOPIFY_QUANTITY_LOCATIONS,
            }).then((response) => {
                if (!response.error) {
                    setProductQuantityFromShopifyLocation(response?.data)
                }
            })
        }
    }, [scanOrigin])

    useEffect(() => {
        if (productQuantityFromShopifyLocation?.length && products?.length) {
            const resultArray = productQuantityFromShopifyLocation.map(productQun =>
                products.find(product => productQun.inventoryItemId === product.inventoryItemId && productQun.available > 0)).filter(item => item !== undefined);
            setProductQuantityArray(resultArray?.length ? resultArray : []);
            setIsProductQuantityLoading(false)
        }
    }, [productQuantityFromShopifyLocation, products])

    useEffect(() => {
        if (timeoutCounter !== 0) {
            setTimeout(() => {
                setTimeoutCounter(timeoutCounter - 1)
            }, 2000);
        }
    }, [timeoutCounter])

    return !isProductQuantityLoading ? (
        <Row className={`${!productQuantityArray?.length && "justify-content-center"}`}>
            {productQuantityArray?.length ? <>
                <Col xl={4} lg={12} className={`d-flex justify-content-center  align-items-center pe-auto ${isScanned && "flex-column"} mt-2`}>
                    <div>
                        <Label for="IsScanned" style={{ fontSize: "50px" }}
                            onClick={(e) => {
                                setIsScanned(!isScanned);
                            }}>
                            <i className="fa fa-qrcode" aria-hidden="true"></i>
                        </Label>
                    </div>
                    {isScanned ? <div className="qr-code-div">
                        {currentStep === 2 && <QrScanner
                            onDecode={(result) => {
                                if (timeoutCounter === 0) {
                                    if (scan !== result) {
                                        addProduct(result)
                                        setScanned(!scan)
                                        setTimeoutCounter(2)
                                    }
                                }
                            }}
                        />}
                    </div> : ""}
                </Col>

                <Col xl={8} lg={12} className="mt-2" >
                    <div className="mt-xl-0 mt-sm-3">
                        <RenderData productQuantityArray={productQuantityArray} scanOrigin={scanOrigin} />
                    </div>
                </Col>
            </> : <div className="d-flex justify-content-center  p-md-5 p-2">Sorry, no available products for this location.</div>}
            <div className="card-footer w-100 mt-3">
                <PreviousButton />
                <Button className="float-right" color="primary" onClick={() => {
                    next()
                }}>
                    Next
                </Button>
            </div>
        </Row >
    ) : <div className="d-flex justify-content-center " > <CircularProgress /></ div>;
};

export default ProductScan;

