import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useLiveQuery } from 'dexie-react-hooks';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { db } from './Dexie/db';
import { TRANSFER_DATA } from '../../../shared/tagFiles';
import { useAddMutation } from '../../../services/pcApi';
import { successToast } from '../../Element/toast';
import Modal from '../../components/Modal';

const Details = ({ PreviousButton }) => {
    const [responseData, setResponseData] = useState("")
    const [isDownloadPDF, setIsDownloadPDF] = useState(false)
    const [isProductPopup, setIsProductPopup] = useState(false);
    const [createTransfer, { isLoading: iscreateTransferLoading }] = useAddMutation();
    const history = useHistory()
    const Products = useLiveQuery(
        async () => {
            const Products = await db.Products.toArray() || [];
            return Products;
        }
    );

    const Shipments = useLiveQuery(
        async () => {
            const Shipments = await db.Shipments.orderBy('id').reverse().toArray() || [];
            return Shipments[0];
        }
    );

    const handleTransfer = () => {
        const newArray = Products.map(({ variantid, quantity }) => ({ VariantId: variantid, Quantity: parseInt(quantity), Accept: 0, Reject: 0 }));
        let payload = {
            "skuObject": JSON.stringify(newArray),
            "shopifyOriginLocationId": Shipments.originId,
            "shopifyDestinationLocationId": Shipments.destinationId,
        }

        createTransfer({
            entity: `Shopify/CreateProductTransfer`,
            data: payload,
            tag: TRANSFER_DATA,
        }).then((response) => {
            if (!response.error) {
                setResponseData(response.data)
                setIsDownloadPDF(true)
                successToast("Data Succefully transferd")
            } else {
                setResponseData({})
                setIsDownloadPDF(false)
                if (response.error.status === 503 || response.error.status === 504) {
                    setIsProductPopup(true)
                }
            }
        });
    }

    const base64ToBlob = (base64String, contentType) => {
        const sliceSize = 1024;
        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    const downloadPdf = () => {
        setIsDownloadPDF(false)
        let dateTime = moment();
        const blob = base64ToBlob(responseData, 'application/pdf');
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Transfer${dateTime.format('DDMMYYYYHHMM')}`;
        link.click();
        window.URL.revokeObjectURL(url);
        history.push("/receiving-list")
    };

    return (<>
        <Row>
            <Col md={6} className='d-flex justify-content-center p-3'>
                <div>
                    <h4 className='d-flex justify-content-center '>Origin</h4>
                    <div>
                        <div><span className='title'>Name: </span><span className='sub-title'>{Shipments?.originName}</span></div>
                        <div><span className='title'>Scan Time: </span><span className='sub-title'>{moment(Shipments?.originScanTime).calendar()}</span></div>
                    </div>
                </div>
            </Col>
            <Col md={6} className='d-flex justify-content-center p-3'>
                <div>
                    <h4 className='d-flex justify-content-center '>Destiny</h4>
                    <div>
                        <div><span className='title'>Name: </span><span className='sub-title'>{Shipments?.destinationName}</span></div>
                        <div><span className='title'>Scan Time: </span><span className='sub-title'>{moment(Shipments?.destinationScanTime).calendar()}</span></div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <h4 className='d-flex justify-content-center m-3 '>Product List</h4>
                <div className='container'>
                    <Table striped bordered hover responsive className="">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Products?.map((product, index) => {
                                    return (
                                        <>
                                            <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td>{product.name}</td>
                                                <td>{product.quantity}</td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Col>
            <div className="card-footer w-100 mt-3">
                <PreviousButton />
                {!isDownloadPDF ? <Button className="float-right" color="primary" disabled={iscreateTransferLoading} onClick={handleTransfer}>
                    {iscreateTransferLoading && (
                        <CircularProgress color="inherit" size={18} className='mr-1' />
                    )}{" "}
                    Transfer
                </Button> :
                    <Button className="float-right" color="primary" onClick={downloadPdf}>
                        Download PDF
                    </Button>
                }
            </div>
        </Row>
        {isProductPopup && (
            <Modal
                color="warning"
                title="Warning"
                message={
                    <div className="p-3">
                        <p>Please try again after sometime</p>
                    </div>
                }
                handleOkClick={() => setIsProductPopup(!isProductPopup)}
                toggle={() => setIsProductPopup(!isProductPopup)}
            />
        )}
    </>)
}

export default Details