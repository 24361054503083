import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AsyncPaginates from "../../shared/components/LazySelect";
import { useAddMutation } from "../../services/pcApi";
import { ASK_ROLE_TAG } from "../../shared/tagFiles";
import { successToast } from "../Element/toast";

const RelationForm = ({ setIsAddModalOpen, newRequest, setIsAlreadyRegistered }) => {
  const [addRelation, { isLoading: isRelationAddLoading }] = useAddMutation();

  const relationValidationSchema = Yup.object().shape({
    Relation: Yup.mixed().required("Please select any role."),
  });

  const onSubmit = (values) => {
    const vendorObjects = values?.Vendors && values.Relation.Name.toLowerCase() === "client" ? values?.Vendors?.map(({ Id, Name }) => ({ Id, Name, Status: "pending" })) : [];
    addRelation({
      entity: `Org/RequestForRegistration?relationId=${values.Relation.Id}&vendors=${encodeURIComponent(JSON.stringify(vendorObjects))}`,
      tag: ASK_ROLE_TAG,
    }).then((response) => {
      if (!response.error) {
        if (!isRelationAddLoading) {
          setIsAddModalOpen(false)
          setIsAlreadyRegistered(false)
        }
        successToast("Relation request successfully.");
      }
    });
  };

  return (
    <Formik
      initialValues={{
        Relation: "",
        Vendors: "",
      }}
      validationSchema={relationValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        } = formik;
        return (
          <Form className="text-left w-100">
            <div className="text-center">
              {newRequest ? <p>Please inform the administrator of the role you are interested in once again.</p>
                : <p>At the moment, you don't have a designated role. <br /> Please inform the admin which role you are interested in.</p>}
            </div>
            <AsyncPaginates
              value={values.Relation}
              label="Role"
              name="Relation"
              className="w-100"
              entity="OrgReference/Relations"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Relation}
              fieldTouched={touched.Relation}
              handleBlur={handleBlur}
            />
            {values?.Relation?.Name?.toLowerCase() === "client" && <AsyncPaginates
              value={values.Vendors}
              className="w-100"
              label="Vendors"
              name="Vendors"
              entity="ShopifyReference/ShopifyVendors"
              setFieldValue={setFieldValue}
              fieldErrors={errors.Vendors}
              fieldTouched={touched.Vendors}
              handleBlur={handleBlur}
              isMulti={true}
              cacheUniqs={[values.Relation]}
            />}
            <Button
              disabled={isRelationAddLoading}
              onClick={handleSubmit}
              className="modal_ok float-right"
              color="success"
            >
              {(isRelationAddLoading) && (
                <CircularProgress color="inherit" size={18} className='mr-1' />
              )}{" "}
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

RelationForm.propTypes = {
  setIsAddModalOpen: PropTypes.func.isRequired,
};

export default RelationForm;
