import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "slice",
  initialState: {
    user: {},
    sales: [],
    prevOption: {},
    preTab: 1,
    isProductLoader: false,
  },
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    getAllSales: (state, action) => {
      state.sales = action.payload;
    },
    previousOption: (state, action) => {
      state.preOption = action.payload;
    },
    previousTab: (state, action) => {
      state.preTab = action.payload;
    },
    isProductLoading: (state, action) => {
      state.isProductLoader = action.payload;
    },
  },
});

export default slice.reducer;
export const {
  loginUser,
  getAllSales,
  previousOption,
  previousTab,
  isProductLoading,
} = slice.actions;
