import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';
import ReactTable from '../../../shared/components/Table'
import { BACKERKIT_PRODUCT, SHOPIFY_PRODUCT } from '../../../shared/tagFiles';
import { useLazyGetAllRecordsQuery, useLazyGetSimpleRecordsQuery } from '../../../services/pcApi';
import { previousOption, previousTab } from "../../../redux/reducers/Slice";
import { db } from '../ProductScan/Dexie/db'

const BackerKitOrders = () => {
    const [getAllBackerKitOrderRecords, { isLoading: isBackerKitLoading }] = useLazyGetAllRecordsQuery();
    const [getAllShopifyGrpOrderRecords, { data: isBackerKitGrpOrderRecords, isLoading: isBackerKitGrpLoading }] = useLazyGetSimpleRecordsQuery();
    const preSelectedTab = useSelector(state => state?.data?.preTab)
    const [activeTab, setActiveTab] = useState(preSelectedTab)
    const [bkOrdersApiCalled, setBkOrdersApiCalled] = useState(false)
    const [bkGrpApiCalled, setBkGrpApiCalled] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch();

    const columns = [
        {
            Header: "Order Number",
            accessor: "BackerKitOrderId",
            Cell: (({ value }) => {
                return value ? `#${value}` : "-"
            })
        },
        {
            Header: "Survey Answered At",
            accessor: "SurveyAnsweredAt",
            type: "Date",
            filter: false,
            Cell: (({ value }) => {
                return value ? moment(value).format('MM-DD-YYYY h: mm a') : "-"
            })
        },
        {
            Header: "Items",
            filter: false,
            Cell: ((value) => {
                const totalQuantity = value?.row?.original?.BackerKitFulfillOrderSkuDTO.reduce((acc, currentItem) => {
                    return acc + currentItem.Quantity;
                }, 0);
                return (totalQuantity <= 1) ? `${totalQuantity} item` : `${totalQuantity} items`
            })
        },
        {
            Header: "Status",
            accessor: "OrderStatusDTO.Name",
            filter: false,
            Cell: (({ value }) => {
                return <span className={`text-capitalize ${value === "ready_to_ship" ? "text-success" : "text-danger"}`}>{value}</span>
            })
        }
    ]

    const grpColumns = [
        {
            Header: "Package Groups",
            accessor: "GroupName",
            Cell: (({ value }) => {
                return value ?? "-"
            })
        },
        {
            Header: "Total Orders",
            accessor: "TotalOrder",
            type: "Number",
            Cell: (({ value }) => {
                return value ?? "-"
            })


        },
    ]

    const backerKitOrders = useLiveQuery(
        async () => {
            const BackerKitOrders = await db.BackerKitOrders.toArray();
            return BackerKitOrders;
        }
    );

    const handleShowDetails = (order) => {
        dispatch(previousOption({
            label: "BackerKit",
            value: "backerkit"
        }))
        history.push(`/backerkit/order-details`, {
            orderData: order
        })
    }

    const handleGrpShowDetails = (order) => {
        dispatch(previousOption({
            label: "BackerKit",
            value: "backerkit"
        }))
        history.push(`/backerkit/order-group-details`, {
            orderData: order
        })
    }

    useEffect(() => {
        if (!bkOrdersApiCalled) {
            getAllBackerKitOrderRecords({
                entity: `BackerKit_Fulfill/BackerKitFulfillOrders`,
                entities: {
                    PledgeStatus: {}, BackerKitFulfillOrderSkus: {}, "BackerKitFulfillOrderSkus.Sku": {}, OrderStatus: {}, Customer: {}, "Customer.Address": {},
                },
                count: true,
                allRecords: true,
                tag: SHOPIFY_PRODUCT,
            }).then((response) => {
                setBkOrdersApiCalled(true)
                if (!response.error) {
                    db.BackerKitOrders.clear();
                    let time = new Date()
                    response?.data?.value?.forEach((item) => {
                        db.BackerKitOrders.add({
                            Id: item.Id,
                            CustomerId: item.CustomerId,
                            RewardId: item.RewardId,
                            OrderStatusId: item.OrderStatusId,
                            PledgeStatusId: item.PledgeStatusId,
                            BackerKitOrderId: item.BackerKitOrderId,
                            Platform: item.Platform,
                            IsEnvelope: item.IsEnvelope,
                            ChargeToken: item.ChargeToken,
                            PledgeAmount: item.PledgeAmount,
                            PledgeAt: item.PledgeAt,
                            ChargedAt: item.ChargedAt,
                            SurveyAnsweredAt: item.SurveyAnsweredAt,
                            OrderStatusDTO: item.OrderStatusDTO,
                            CustomerDTO: item.CustomerDTO,
                            PledgeStatusDTO: item.PledgeStatusDTO,
                            BackerKitFulfillOrderSkuDTO: item.BackerKitFulfillOrderSkuDTO,
                            LastSynced: time,
                        })
                    })
                }
            })
        }

        if (!bkGrpApiCalled) {
            getAllShopifyGrpOrderRecords({
                entity: `BackerKit_Fulfill/GetAllGroupingOrderDetailFromBackerKit?$count=true&$orderby=Id desc&$expand=OrderdetailDTOs,SkuDetailDTOs`,
                tag: BACKERKIT_PRODUCT,
            }).then(() => setBkGrpApiCalled(true))
        }
    }, [bkOrdersApiCalled, bkGrpApiCalled])

    return (
        <>
            <div className="card__title d-flex justify-content-between align-items-center mb-2">
                <div className='d-flex align-items-baseline'>
                    <h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i>BackerKit Orders</h5>
                    <span style={{ cursor: "pointer" }} onClick={() => +activeTab === 1 ? setBkOrdersApiCalled(false) : setBkGrpApiCalled(false)}><i className="fa fa-refresh ml-2" aria-hidden="true"></i></span>                </div>
            </div>
            <div className=''>
                <Tabs
                    activeKey={activeTab}
                    defaultActiveKey={activeTab}
                    onSelect={(e) => {
                        setActiveTab(e)
                        dispatch(previousTab(e))
                    }}
                    id="fill-tab-example"
                >
                    <Tab eventKey={1} title="All Orders">
                        <ReactTable columns={columns} data={backerKitOrders?.length ? backerKitOrders : []} isLoading={isBackerKitLoading} hasHandleShow handleShowDetails={handleShowDetails} disabledGlobalSearch />
                    </Tab>
                    <Tab eventKey={2} title="Package Groups">
                        <ReactTable className="w-50" columns={grpColumns} data={isBackerKitGrpOrderRecords?.value?.length ? isBackerKitGrpOrderRecords?.value : []} isLoading={isBackerKitGrpLoading} hasHandleShow handleShowDetails={handleGrpShowDetails} disabledGlobalSearch />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}



export default BackerKitOrders