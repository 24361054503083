import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'universal-cookie';
import { CircularProgress } from '@material-ui/core';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from './ProductScan/Dexie/db';
import { useLazyGetRecordsQuery, useLazyGetSimpleRecordsQuery } from '../../services/pcApi';
import { SHOPIFY_PRODUCT, SHOPIFY_QUANTITY_LOCATIONS } from '../../shared/tagFiles';
import ReactTable from '../../shared/components/Table';

const AllProducts = () => {
    const loginUser = useSelector(state => state?.data?.user)
    const [allProducts, setAllProducts] = useState([]);
    const [pageInfo, setPageInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [firstProductData, setFirstProductData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState()
    const [productQuantityFromShopifyLocation, setProductQuantityFromShopifyLocation] = useState([]);
    const [isLocationOptions, setIsLocationOptions] = useState([]);
    const [getAllProductQuantityFromShopifyLocation] = useLazyGetRecordsQuery();
    const [getAllShopifyProductRecords] = useLazyGetSimpleRecordsQuery();
    let cookies = new Cookies()
    let accessToken = cookies.get("accessToken");
    const history = useHistory()
    const { isAuthenticated } = useAuth0()

    const products = useLiveQuery(
        async () => {
            const AllShopifyProducts = await db.AllShopifyProducts.toArray();
            return AllShopifyProducts;
        }
    );

    const locations = useLiveQuery(
        async () => {
            const Locations = await db.Locations.toArray();
            return Locations;
        }
    );

    const columns = [
        {
            Header: "Product",
            accessor: "productTitle",
            Cell: (({ value }) => {
                return value ?? "-"
            })
        },
        {
            Header: "Vendor",
            accessor: "productVendor",
            Cell: (({ value }) => {
                return value ?? "-"
            })
        },
        {
            Header: "SKU",
            accessor: "variantSku",
            Cell: (({ value }) => {
                return value ?? "-"
            })
        },
        {
            Header: "Price",
            accessor: "variantPrice",
            type: "Number",
            Cell: (({ value }) => {
                return value ? `$${parseFloat(value).toFixed(2)}` : "-";
            })
        },
        {
            Header: "Quantity",
            accessor: "inventoryQuantity",
            type: "Number",
            Cell: (({ value }) => {
                return value ?? "-"
            })
        },
        {
            Header: "Status",
            accessor: "productStatus",
            Cell: (({ value }) => {
                return value ? (
                    <span
                        className={`product-status ${value === "active"
                            ? "bg-success"
                            : value === "draft"
                                ? "bg-primary"
                                : "bg-secondary"
                            }`}
                    >
                        {value}
                    </span>)
                    : "-"
            })
        }
    ]

    useEffect(() => {
        if ((loginUser && Object.entries(loginUser)?.length && products?.length)) {
            if (loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "employee") {
                setData(products)
                setIsLoading(false)
            } else {
                const vendorArray = JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject);
                const filteredProducts = products.filter(product => {
                    return vendorArray.some(vendor => vendor.Name === product.productVendor);
                });
                setIsLoading(false)
                setData(filteredProducts)
            }
        }

        if (productQuantityFromShopifyLocation?.length && products?.length) {
            const resultArray = productQuantityFromShopifyLocation.map(productQun =>
                products.find(product => productQun.inventoryItemId === product.inventoryItemId)).filter(item => item !== undefined);

            const vendorArray = JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject);

            const filteredProducts = vendorArray?.length ? resultArray.filter(product => {
                return vendorArray?.some(vendor => vendor.Name === product.productVendor);
            }) : resultArray;

            setData(filteredProducts)
            setIsLoading(false)
        }

    }, [products, productQuantityFromShopifyLocation, loginUser])

    useEffect(() => {
        if (selectedLocation && Object.entries(selectedLocation)?.length > 0) {
            if (selectedLocation?.id === 0) {
                if (loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "employee") {
                    setData(products)
                    setIsLoading(false)
                } else {
                    const vendorArray = JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject);
                    const filteredProducts = products?.filter(product => {
                        return vendorArray?.some(vendor => vendor?.Name === product?.productVendor);
                    });
                    setData(filteredProducts)
                    setIsLoading(false)
                }
            }
            else {
                getAllProductQuantityFromShopifyLocation({
                    entity: `Shopify/GetProductQuantityFromShopifyLocation?locationIds=${selectedLocation?.id}`,
                    tag: SHOPIFY_QUANTITY_LOCATIONS,
                }).then((response) => {
                    if (!response.error) {
                        setProductQuantityFromShopifyLocation(response?.data)
                    }
                })
            }
        }
    }, [selectedLocation])

    const fetchProducts = (entity) => {
        return getAllShopifyProductRecords({
            entity: entity,
            tag: SHOPIFY_PRODUCT,
        });
    };

    useEffect(() => {
        if (locations && locations?.length > 1) {
            let allLocation = []
            locations.forEach((location) => {
                allLocation.push({
                    id: location?.id,
                    label: location?.name,
                    value: location?.name
                })
            })
            const sortedLocations = [{
                id: 0,
                label: "All Locations",
                value: "All Locations"
            }, ...allLocation.sort((a, b) => a.label.localeCompare(b.label))];
            setIsLocationOptions(sortedLocations)
        }
    }, [locations, productQuantityFromShopifyLocation])

    useEffect(() => {
        if (isAuthenticated && accessToken?.length && products?.length === 0) {
            setData([])
            setFirstProductData([])
            setAllProducts([])
            setLoading(true);
            const fetchData = () => {
                let entity = `Shopify/GetProductDetailFromShopify`;
                fetchProducts(entity).then(({ data }) => {
                    setData(data?.items);
                    setFirstProductData(data?.items);
                    setPageInfo(data);
                });
            };
            fetchData();
        }
    }, [isAuthenticated, accessToken, products]);

    useEffect(() => {
        const fetchNextPage = () => {
            let entity = `Shopify/GetProductDetailFromShopify?pageInfo=${pageInfo?.linkHeader?.nextLink.pageInfo}`
            fetchProducts(entity)
                .then(({ data }) => {
                    setAllProducts([...allProducts, ...data.items]);
                    setPageInfo(data);
                });
        };

        if (pageInfo && pageInfo?.hasNextPage) {
            fetchNextPage();
        } else if (pageInfo && !pageInfo?.hasNextPage) {
            setLoading(false)
        }
    }, [pageInfo]);

    useEffect(() => {
        if (pageInfo != null && !pageInfo?.hasNextPage) {
            setAllProducts([...firstProductData, ...allProducts])
            let newAllProduct = [...firstProductData, ...allProducts]
            let time = new Date()
            db.AllShopifyProducts.clear();
            newAllProduct?.forEach((item, index) => {
                item.variants.forEach(variant => {
                    db.AllShopifyProducts.add({
                        Id: index + 1,
                        varinatId: variant?.id,
                        productImage: item?.images?.length ? item.images[0]?.src : '',
                        productId: variant?.productId,
                        productTitle: item?.title,
                        productVendor: item?.vendor,
                        productStatus: item?.status,
                        inventoryItemId: variant?.inventoryItemId,
                        variantTitle: variant?.title,
                        variantPrice: variant?.price?.toString(),
                        variantSku: variant?.sku,
                        inventoryQuantity: variant?.inventoryQuantity,
                        inventoryTrackable: variant?.inventoryManagement == "shopify" ? true : false,
                        barcode: variant?.barcode ?? 0,
                        weight: variant.weight,
                        weightUnit: variant.weightUnit,
                        grams: variant.grams,
                        lastSynced: time,
                    })
                })
            })
        }
    }, [pageInfo])

    return useMemo(() => {
        return (
            data?.length ? (
                <>
                    <div className='py-3'>
                        <div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
                            <div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
                                <div className="card__title d-flex justify-content-between align-items-center mb-2"><div className='d-flex align-items-baseline'><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i> Products</h5></div>
                                    <Button onClick={() => db.AllShopifyProducts.clear()} disabled={loading}>
                                        {loading && <CircularProgress color="inherit" size={18} className='mr-1' />}
                                        Refresh
                                    </Button>
                                </div>
                                <div>
                                    <ReactTable columns={columns} data={data?.length ? data : []} isLoading={(isLoading || loading)} ExtraStuff={() => {
                                        return (
                                            loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "employee" ?
                                                <Select
                                                    className={`lazySelect`}
                                                    name="selectedLocation"
                                                    value={selectedLocation}
                                                    options={isLocationOptions}
                                                    placeholder={`Select location`}
                                                    onChange={(e) => {
                                                        setIsLoading(true)
                                                        setSelectedLocation(e)
                                                    }}
                                                />
                                                :
                                                <div />
                                        )
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : <div className="d-flex justify-content-center "><CircularProgress /></div>
        )
    }, [loading, isLoading, isLocationOptions, data])
}

export default AllProducts