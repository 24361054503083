import React, { useState, useMemo, useEffect } from 'react'
import Select from 'react-select';
import ShopifyOrders from './Orders/ShopifyOrders';
import BackerKitOrders from './Orders/BackerKitOrders';
import { successToast } from '../Element/toast';
import { useSelector } from 'react-redux';

const AllOrders = () => {
    const [selectedStore, setSelectedStore] = useState()
    const previousOption = useSelector(state => state?.data?.preOption)

    const isStoreOptions = [
        {
            label: "Shopify",
            value: "shopify"
        },
        {
            label: "Amazon",
            value: "amazon"
        },
        {
            label: "BackerKit",
            value: "backerkit"
        },
    ]

    useEffect(() => {
        setSelectedStore(previousOption?.label ? previousOption : isStoreOptions[0])
    }, [previousOption])


    return useMemo(() => {
        return (
            <>
                <div className='py-3'>
                    <div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
                        <div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
                            <div className='dropdown-div float-right'>
                                <Select
                                    className={`lazySelect float-right`}
                                    name="selectedStore"
                                    value={selectedStore}
                                    options={isStoreOptions}
                                    placeholder={`Select store`}
                                    defaultValue={selectedStore}
                                    onChange={(e) => {
                                        if (e.value === "amazon") {
                                            successToast("Amazon orders coming soon")
                                            setSelectedStore(isStoreOptions[0]);
                                        } else setSelectedStore(e);
                                    }}
                                />
                            </div>
                            <div className='pt-md-0 pt-5'>
                                {selectedStore?.value === "backerkit" ? <BackerKitOrders /> : <ShopifyOrders />}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }, [selectedStore]);

}

export default AllOrders