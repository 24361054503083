import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { FormGroup, Label } from "reactstrap";

const AsyncPaginates = ({
  value = {},
  label,
  name,
  entity = "",
  options,
  filter = "",
  query = "",
  className,
  allValues,
  index,
  setFieldValue,
  fieldErrors,
  fieldTouched,
  handleBlur,
  readonly = false,
  required = false,
  expandRelation = [],
  isAllowToAddContent = false,
  handleEditRecords = false,
  isMulti = false,
  cacheUniqs = [],
  handleChange
}) => {
  const loadOptions = async (e, _, { page }) => {
    if (options) {
      let finalOptions = options;
      if (e) {
        finalOptions = options.filter((value) => value.Name.includes(e));
      }
      return {
        options:
          finalOptions && finalOptions.length
            ? finalOptions.slice((page - 1) * 5, page * 5)
            : finalOptions,
        hasMore: page * 5 < finalOptions.length,
        additional: {
          page: page + 1,
        },
      };
    } else {
      const baseUrl = process.env.REACT_APP_API_URL;
      const urlString = `${baseUrl}${entity}${name === "File" ? "&" : "?"}$skip=${(page - 1) * 5
        }&$top=5&$count=true&$orderby=Id desc&$filter=IsDeleted ne true ${filter.length ? `and ${filter}` : ""
        }${query.length ? `&${query}` : ""}`;

      let updatedUrlString = "";
      if (value && e) updatedUrlString = urlString;
      if (e || !value)
        updatedUrlString =
          urlString + ` and (contains(Name,'${e.replaceAll("'", "''")}'))`;
      if (!e) updatedUrlString = urlString;
      let accessToken = localStorage.getItem("accessToken");
      const response = await fetch(updatedUrlString, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const responseJSON = await response.json();

      return {
        options: responseJSON.value,
        hasMore: page * 5 < responseJSON["@odata.count"],
        additional: {
          page: page + 1,
        },
      };
    }
  };
  const handleExistingRecords = (e, setFieldValue) => {
    let secondObject = JSON.parse(e.ContentObject)
    let firstObject = JSON.parse(handleEditRecords.ContentObject)
    for (let k in firstObject) {
      if (k === 'lists' || k === 'images') {
        let existingLists = []
        firstObject[k] && firstObject[k].forEach((val, pIndex) => {
          if (firstObject[k].some((_, cIndex) => pIndex === cIndex)) {
            existingLists.push(firstObject[k][pIndex])
          } else {
            existingLists.push(val)
          }
          secondObject[k] = existingLists;
        })
      }
      else {
        secondObject[k] = firstObject[k];
      }
    }
    setFieldValue(
      "ContentObject",
      JSON.parse(secondObject ? JSON.stringify(secondObject) : "{}")
    );
  }

  const setAllFieldValues = (e) => {
    if (name === "File") {
      const contentObjectData = allValues.ContentObject && allValues.ContentObject;
      contentObjectData.images[index].url = e.CloudinaryAddress;
      contentObjectData.images[index].name = e.Name;
      setFieldValue("ContentObject", contentObjectData);
    } else {
      setFieldValue(name, e);
      if (isAllowToAddContent) {
        if (Object.entries(handleEditRecords).length && value.ContentObject !== e.ContentObject) {
          handleExistingRecords(e, setFieldValue)
        } else {
          setFieldValue(
            "ContentObject",
            JSON.parse(e.ContentObject ? e.ContentObject : "{}")
          );
        }
      }
    }
  }

  return (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <font color="red"> *</font>}
      </Label>
      <AsyncPaginate
        className={`lazySelect ${readonly && "disabled"} ${className}`}
        value={value}
        cacheUniqs={cacheUniqs}
        loadOptions={loadOptions}
        getOptionValue={(option) => option.Id}
        getOptionLabel={(option) => {
          if (option.Id) {
            if (expandRelation.length && option && option[expandRelation[0]]) {
              let optionString = `${option.Id}`;
              optionString +=
                "-" +
                expandRelation
                  .map((value) => {
                    return `${option[value].Name}`;
                  })
                  .join("-");
              return optionString;
            } else if (entity === "User") {
              return `${option.Id} - ${option.Name} - ${option.Email}`;
            } else if (entity === "Tile") {
              return `${option.Id} - ${option.UrlFileName}`;
            } else if (entity === "GetAllCell") {
              return `${option.Id} - (X : ${option.X}) (Y : ${option.Y}) (Z : ${option.Z})`;
            } else if (name === "File") {
              return <><img src={option?.CloudinaryAddress ?? option.url} style={{ width: "50px" }} alt="" /> {option.Name ?? option.name}</>;
            } else {
              return `${option.Id} - ${option.Name}`;
            }
          } else {
            if (option.Name) return `${option.Name}`
            else return `New ${name}`
          }
        }}
        placeholder={`Select ${label}`}
        isSearchable={true}
        onBlur={handleBlur}
        onChange={handleChange ? handleChange : setAllFieldValues}
        isDisabled={readonly}
        isMulti={isMulti}
        additional={{
          page: 1,
        }}
      />
      {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
    </FormGroup>
  );
};

export default AsyncPaginates;
