import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const AddModal = ({ title, message, toggle, color, bodyclassName, footer, modelStyle, xl, xxl, testid }) => {
  return (
    <div data-testid={testid}>
      <Modal
        centered
        isOpen={true}
        toggle={toggle}
        className={`modal-dialog--${color} ${xl ? "modal-dialog--header-xl" : "modal-dialog--header"
          } ${xxl ? "modal-dialog--header-xxl" : ""} modal-dialog-box theme-light`}
      >
        <ModalHeader toggle={toggle} style={{ backgroundColor: color }}>{title}</ModalHeader>
        <ModalBody className={bodyclassName} style={modelStyle}>
          {message}
        </ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </Modal>
    </div>
  );
};

AddModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.object,
  color: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
  xl: PropTypes.bool,
};

AddModal.defaultProps = {
  title: "",
  message: {},
  size: "",
  bodyclassName: ""
};

export default connect((state) => ({
  rtl: state.rtl,
}))(AddModal);
