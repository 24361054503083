import React from 'react'
import { useHistory } from 'react-router-dom';
import { Col, Row, Table } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';

const SalesBreakdown = (props) => {
	const data = props?.location?.state?.data;
	const allVendors = props?.location?.state.allVendors;
	const history = useHistory()

	const loginUser = props?.location?.state?.loginUser;
	const findCommission = () => {
		const vendorNames = JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject);
		const filteredVendors = (vendorNames?.length ? vendorNames : allVendors)?.filter(vendor => vendor?.Name === data?.ProductVendor);
		if (filteredVendors?.length > 0) {
			const commission = filteredVendors[0]?.Commission;
			return commission ? `${commission}%` : "Commission not available";
		} else {
			return "Vendor not found";
		}
	};

	return data ? (
		<>
			<div className='py-3'>
				<div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
					<div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
						<div className="card__title"><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i> Commission Breakdown</h5></div>
						<Row>
							<Col xl={12} lg={12} className="sales-details-container">
								<Table className='mb-xl-0 mb-3 ' bordered>
									<tbody>
										<tr>
											<td><b>Product Title:</b></td>
											<td>{data?.ProductTitle ?? "-"}</td>
										</tr>
										<tr>
											<td><b>Product Vendor:</b></td>
											<td>{data?.ProductVendor ?? "-"}</td>
										</tr>
										<tr>
											<td><b>Variant Title:</b></td>
											<td>{data?.VariantTitle?.length ? data?.VariantTitle : "-"}</td>
										</tr>
										<tr>
											<td><b>Variant SKU:</b></td>
											<td>{data?.VariantSKU ?? "-"}</td>
										</tr>
										<tr>
											<td><b>Commission Percentage:</b></td>
											<td>{findCommission()}</td>
										</tr>
										<tr>
											<td><b>Price:</b></td>
											<td>${(data?.Price).toFixed(2) ?? "0"}</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row className='mt-5'>
							{data.QuarterQuantityDTOs.map((item) => {
								return (
									<Col xl={6} lg={12} className="sales-details-container mt-2">
										<Table className='mb-xl-0 mb-3' bordered>
											<thead>
												<tr>
													<th colSpan="2" className="text-center"><h5>Q{item.Quarter} {item.Year}</h5></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><b>Total Quantity</b></td>
													<td className="text-right">{item?.SalesQuantity + item?.SupplementaryQuantity}</td>
												</tr>
												<tr>
													<td><b>Gross Sale</b></td>
													<td className="text-right">${parseFloat(item?.GrossSale + item?.SupplementaryGrossSale).toFixed(2)}</td>
												</tr>
												<tr>
													<td><b>Discount</b></td>
													<td className="text-right">- ${parseFloat(item?.Discount + item?.SupplementaryDiscount).toFixed(2)}</td>
												</tr>
												<tr>
													<td><b>Returns ({item?.ReturnQuantity + item.SupplementaryReturnQuantity})</b></td>
													<td className="text-right">- ${parseFloat(item?.Returns + item?.SupplementaryReturn).toFixed(2)}
														<div>
															<hr className='w-50 mb-0' style={{ float: 'right' }} />
														</div>
													</td>
												</tr>
												<tr>
													<td><b>Net Sales</b></td>
													<td className="text-right">${parseFloat(item?.NetSales + item?.SupplementaryNetSale).toFixed(2)}</td>
												</tr>
												<tr>
													<td><b>Your Commission ({findCommission()})</b></td>
													<td className="text-right">${parseFloat(item?.Commission + item?.SupplementaryCommission).toFixed(2)}</td>
												</tr>
											</tbody>
										</Table>
									</Col>
								)
							})}
						</Row>
					</div>
				</div>
			</div>
		</>
	) : <div className="d-flex justify-content-center "><CircularProgress /></div>;
}

export default SalesBreakdown

