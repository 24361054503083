import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Input, Progress, Table } from 'reactstrap';
import { Col, Modal, Row } from 'react-bootstrap';
import { useLiveQuery } from 'dexie-react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { db } from '../ProductScan/Dexie/db';
import noImage from "../../../images/noImage.png"
import { useUpdateMutation } from '../../../services/pcApi';
import { RECEIVE_DATA } from '../../../shared/tagFiles';
import { successToast } from '../../Element/toast';

const ReceivingDetails = () => {
	const loginUser = useSelector(state => state?.data?.user)
	const [responseData, setResponseData] = useState("")
	const [isProductPopup, setIsProductPopup] = useState(false);
	const [isWarning, setIsWarning] = useState(false);
	const [isDownloadPdf, setIsDownloadPdf] = useState(false);
	const [updateTransfer, { isLoading: isUpdateTransferLoading }] = useUpdateMutation();
	const location = useLocation();
	const transferData = location.state?.sku
	const [data, setData] = useState(JSON.parse(transferData?.SkuObject))
	const history = useHistory()

	const totalQuantity = data?.reduce((total, item) => total + parseInt(item?.Quantity), 0) ?? 0;
	const totalAccept = data?.reduce((total, item) => total + parseInt(item?.Accept ?? 0), 0);
	const totalReject = data?.reduce((total, item) => total + parseInt(item?.Reject ?? 0), 0);

	const products = useLiveQuery(
		async () => {
			const AllShopifyProducts = await db.AllShopifyProducts.toArray();
			return AllShopifyProducts;
		}
	);

	const getTitle = (d) => {
		if (products?.length) {
			let a = products?.find((pr) => pr.varinatId === d.VariantId)
			return a
		}
	}

	const handleQuantity = (e, i, status) => {
		setIsWarning(false)
		setIsDownloadPdf(false)
		let array = data
		if (status === "accept") {
			array[i].Accept = e.target.value ? parseInt(e.target.value) : 0
		} else {
			array[i].Reject = e.target.value ? parseInt(e.target.value) : 0
		}
		setData([...array])
	}

	const handleTransfer = () => {
		if (data && Object.entries(data)?.length) {
			if (!(totalAccept || totalReject)) {
				setIsWarning(true)
			} else {
				const newArray = data?.map((product) => {
					return {
						VariantId: product.VariantId,
						Quantity: parseInt(product?.Quantity),
						Accept: product.Accept ?? 0,
						Reject: product.Reject ?? 0,
					};
				});

				let payload = {
					Id: transferData.Id,
					SkuObject: JSON.stringify(newArray),
				}
				updateTransfer({
					entity: `Shopify/ReceiveProductTransfer?id=${transferData.Id}`,
					data: payload,
					tag: RECEIVE_DATA,
				}).then((response) => {
					if (!response.error) {
						setResponseData(response.data)
						setIsDownloadPdf(true)
						successToast("Data Succefully transferd")
					} else {
						setResponseData({})
						if (response.error.status === 503) {
							setIsProductPopup(true)
						}
					}
				});
			}
		} else setIsWarning(true)
	}

	const base64ToBlob = (base64String, contentType) => {
		const sliceSize = 1024;
		const byteCharacters = atob(base64String);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};

	const downloadPdf = () => {
		let dateTime = moment();
		const blob = base64ToBlob(responseData, 'application/pdf');
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `Receiving${dateTime.format('DDMMYYYYHHMM')}`;
		link.click();
		window.URL.revokeObjectURL(url);
		history.push("/receiving-list")
	};

	return transferData && Object.keys(transferData)?.length && Object.keys(loginUser)?.length ? (
		<>
			<div className='p-md-5 p-3'>
				<div className='container '>
					<div className='d-flex mb-3 justify-content-between '>
						<div className='d-flex'>
							<div className="Polaris-Page-Header__BreadcrumbWrapper">
								<div className="Polaris-Box Polaris-Box--printHidden">
									<i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i>
								</div>
							</div>
							<div className='receive-inventory-header'>
								<div className="receive-inventory-header-title-title-wrapper">
									<p className="receive-inventory-header-title">Receive items</p>
								</div>
								<div className="receive-inventory-header-sub-title-wrapper">
									<p className="receive-inventory-header-sub-title">#{transferData.Id}</p>
								</div>
							</div>
						</div>

						<div>
							{!isDownloadPdf ? <Button className="float-right" color="primary" disabled={isUpdateTransferLoading || loginUser?.userRelationRequestDTO?.relation === 'Client'} onClick={handleTransfer}>
								{isUpdateTransferLoading && (
									<CircularProgress color="inherit" size={18} className='mr-1' />
								)}{" "}
								Save
							</Button> :
								<Button className="float-right" color="primary" onClick={downloadPdf}>
									Download PDF
								</Button>
							}
						</div>
					</div>
					{isWarning && (
						<div className="alert alert-danger alert-dismissible fade show" role="alert">
							<h4 className="alert-heading">Transfer receive failed</h4>
							<hr />
							<p className="mb-0">Couldn’t receive. Add 1 or more items to this receipt and try again.</p>
							<button type="button " className="close" onClick={() => setIsWarning(!isWarning)}>
								<span className=" font-weight-bold text-black" style={{ fontSize: "30px" }} aria-hidden="true">&times;</span>
							</button>
						</div>
					)}
					<div className="d-flex justify-content-center mb-3">
						<Row className="container receiving-div ">
							<Col md={6} className="p-3" style={{ borderRight: "2px solid #f8f8f8" }}>
								<label style={{ fontSize: "15px", marginBottom: "15px" }}>Origin</label>
								<h5>{transferData?.OriginLocation?.Name}</h5>
								<text>
									{(transferData?.OriginLocation?.Line1 ? transferData?.OriginLocation?.Line1 + ", " : "") +
										(transferData?.OriginLocation?.Line2 ? transferData?.OriginLocation?.Line2 + ", " : "") +
										(transferData?.OriginLocation?.ProvinceCode ? transferData?.OriginLocation?.ProvinceCode : "")
									}
								</text>

							</Col>
							<Col md={6} className="p-3" >
								<label style={{ fontSize: "15px", marginBottom: "15px" }}>Destination</label>
								<h5>{transferData?.DestinationLocation?.Name}</h5>
								<text>
									{(transferData?.DestinationLocation?.Line1 ? transferData?.DestinationLocation?.Line1 + ", " : "") +
										(transferData?.DestinationLocation?.Line2 ? transferData?.DestinationLocation?.Line2 + ", " : "") +
										(transferData?.DestinationLocation?.ProvinceCode ? transferData?.DestinationLocation?.ProvinceCode : "")
									}
								</text>
							</Col>
						</Row>
					</div>
					<div className="d-flex justify-content-center " >
						<Row className="container receiving-div ">
							<Col md={12} className="p-3" style={{ borderRight: "2px solid #f8f8f8" }}>
								<label style={{ fontSize: "15px", marginBottom: "15px" }}>Products</label>
								<div className='mb-3'>
									<Progress multi
										style={{ height: "10px" }}
									>
										<Progress
											bar
											striped
											color="success"
											max={totalQuantity}
											value={totalAccept}
										/>
										<Progress
											bar
											striped
											color="danger"
											max={totalQuantity}
											value={totalReject}
										/>
									</Progress>
									<span className="float-right text-right">
										Total received: {totalAccept + totalReject} of {totalQuantity}
									</span>
								</div>
								<Table responsive>
									<thead>
										<tr>
											<th>Products</th>
											<th>SKU</th>
											<th>Accept</th>
											<th>Reject</th>
											<th>Received</th>
										</tr>
									</thead>
									<tbody>
										{
											data?.map((product, i) => {
												return (
													<tr key={i}>
														<td className='d-flex'><img className='product-img'
															src={getTitle(product)?.productImage || noImage}
															alt={getTitle(product)?.variantTitle}
														/>
															<span>
																<h6 className='mb-2'>{getTitle(product)?.productTitle}</h6>
																<text>{getTitle(product)?.variantTitle}</text>
															</span>
														</td>
														<td>{getTitle(product)?.variantSku}</td>
														<td className="table-td">
															<div className="table-td-accept d-flex">
																<div>
																	<Input
																		className="ml-0 mt-1 table-input-text-field"
																		name="productAccept"
																		type="number"
																		value={product?.Accept}
																		placeholder='0'
																		onChange={(e) => handleQuantity(e, i, "accept")}
																		disabled={loginUser?.userRelationRequestDTO?.relation === 'Client'}
																	/>
																</div>
																{/* <div className="Polaris-Connected__Item">
																	<Button>
																	<span>All</span>
																	</Button>
																</div> */}
															</div>
														</td>
														<td className="table-td">
															<div className="table-td-accept d-flex">
																<div>
																	<Input
																		className="ml-0 mt-1 table-input-text-field"
																		name="productAccept"
																		type="number"
																		value={product?.Reject}
																		placeholder='0'
																		onChange={(e) => handleQuantity(e, i)}
																		disabled={loginUser?.userRelationRequestDTO?.relation === 'Client'}
																	/>
																</div>
																{/* <div className="Polaris-Connected__Item">
																	<Button>
																		<span>All</span>
																	</Button>
																</div> */}
															</div>
														</td>
														<td>
															<Progress multi
																style={{ height: "10px" }}
															>
																<Progress
																	bar
																	striped
																	color="success"
																	max={product.Quantity}
																	value={product.Accept}
																/>
																<Progress
																	bar
																	striped
																	color="danger"
																	max={product.Quantity}
																	value={product.Reject}
																/>
															</Progress>
															<span>{(parseInt(product?.Accept, 10) || 0) + (parseInt(product?.Reject, 10) || 0)} of {product.Quantity}</span>
														</td>
													</tr>)
											})
										}
									</tbody>
								</Table>
							</Col>
						</Row>
					</div>
				</div>
			</div >
			{isProductPopup && (
				<Modal
					color="warning"
					title="Warning"
					message={
						<div className="p-3">
							<p>Please try again after sometime</p>
						</div>
					}
					handleOkClick={() => setIsProductPopup(!isProductPopup)}
					toggle={() => setIsProductPopup(!isProductPopup)}
				/>
			)}
		</>
	) : <div className="d-flex justify-content-center "><CircularProgress /></div>;
}

export default ReceivingDetails
