import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import useScrollPosition from "use-scroll-position";
import { db } from './markup/Pages/ProductScan/Dexie/db';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from "universal-cookie"
import Markup from './markup/Markup';
import Modal from './markup/components/Modal';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
import './plugins/slick/slick.min.css';
import './plugins/slick/slick-theme.min.css';
import "./scss/app.scss";

function App() {
	const [body_, setbody_] = useState();
	const [header, setHeader] = useState("fixed");
	const [header_, setHeader_] = useState();
	let scrollPosition = useScrollPosition();
	const IDLE_TIMEOUT = 3600000;
	const POPUP_TIMEOUT = 10 * 1000;

	const [isLoggedIn, setIsLoggedIn] = useState(true);
	const [isPopup, setIsPopup] = useState(false);
	const [lastActiveTime, setLastActiveTime] = useState(moment());
	const { isAuthenticated, logout } = useAuth0();
	const cookies = new Cookies();

	const handleActivity = () => {
		setLastActiveTime(moment());
	};

	useEffect(() => {
		setbody_(document.querySelector("body"));
		setHeader_(document.getElementsByClassName("main-bar-wraper"));
	}, []);


	var element = document.getElementById("fix-header");

	if (typeof (element) != 'undefined' && element != null) {
		header === "fixed" && scrollPosition > 10
			? header_ && header_[0].classList.add("is-fixed")
			: header_ && header_[0].classList.remove("is-fixed");
	}

	useEffect(() => {
		if (isAuthenticated) {
			const intervalId = setInterval(() => {
				const now = moment();
				const isInactive = now.diff(lastActiveTime) > IDLE_TIMEOUT;
				if (isLoggedIn && isInactive) {
					setIsPopup(true);
					clearInterval(intervalId);
				}
			}, 1000);

			return () => clearInterval(intervalId);
		}
	}, [isLoggedIn, lastActiveTime]);

	useEffect(() => {
		if (isAuthenticated) {
			const handleLogout = () => {
				setIsLoggedIn(false);
				logout({ returnTo: process.env.REACT_APP_LOGIN_REDIRECT });
				cookies.remove("accessToken");
				localStorage.removeItem("accessToken");
				db.AllShopifyProducts.clear();
				db.Transfers.clear();
				db.Locations.clear();
				db.Sales.clear();
				db.SalesByQuarterly.clear()
				db.ShopifyOrders.clear()
				db.BackerKitOrders.clear()
				setIsPopup(false)
			};

			let interval = () => { }
			if (isPopup) {
				interval = setInterval(() => {
					const now = moment();
					const isInactive = (now.diff(lastActiveTime) + 10000) > IDLE_TIMEOUT + POPUP_TIMEOUT;
					if (isPopup && isInactive) {
						handleLogout();
					}
				}, 10000);
			}
			return () => clearInterval(interval);
		}
	}, [isPopup])


	return (
		<>
			<div className="App" onMouseMove={handleActivity} onKeyDown={handleActivity} >
				<Markup />
			</div>
			{isPopup && (
				<Modal
					color="warning"
					title="Warning"
					message={`Are you still here?`}
					handleOkClick={() => setIsPopup(!isPopup)}
					toggle={() => setIsPopup(!isPopup)}
					isSuggestion
					buttonOkName="Yes"
				/>
			)}
		</>
	);
}

export default App;
