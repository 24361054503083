import React, { useState, useEffect } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { useLazyGetAllRecordsQuery } from '../../../services/pcApi';
import { SHOPIFY_QUANTITY_LOCATIONS, SHOPIFY_TRANSFERS } from '../../../shared/tagFiles';
import { db } from '../../Pages/ProductScan/Dexie/db';

const DashboardLink = ({ to, label, lastSynced, isLoading, count, onClick }) => {
    const linkContent = (
        <div>
            <Link to={to}>
                <div className='dashboard_div site-button'>
                    <CardBody className="dashboard_card-widget">
                        <div className='dashboard_total'>
                            {count ?? 0}
                        </div>
                        <div>{label}</div>
                    </CardBody>
                </div>
            </Link>
            {lastSynced ? <div className='dashboard_last-synced mt-1'>Last synced:- {lastSynced} <span style={{ cursor: "pointer" }} onClick={onClick}><i className="fa fa-refresh ml-2 text-gray" aria-hidden="true"></i></span></div> : ""}
        </div>
    );

    if (isLoading) {
        return (
            <div className='dashboard_div site-button' style={{ opacity: 0.5, pointerEvents: 'none' }}>
                <CardBody className="dashboard_card-widget">
                    <div className='dashboard_total'>
                        <CircularProgress color="inherit" size={15} />
                    </div>
                    <div>{label}</div>
                </CardBody>
            </div>
        );
    }

    return linkContent
};

const TotalBox = ({ loginUser, isProdLoading }) => {
    const [isLocationsLoading, setIsLocationsLoading] = useState(false);

    const products = useLiveQuery(
        async () => {
            const AllShopifyProducts = await db.AllShopifyProducts.toArray();
            const vendorArray = loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "client"
                ? JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject || '[]')
                : [];
            if (vendorArray?.length) {
                const filteredProducts = AllShopifyProducts.map(vendor => {
                    const product = vendorArray.find(product => product.Name === vendor.productVendor);
                    if (product) return vendor
                }).filter(Boolean);
                return filteredProducts;
            } else {
                return AllShopifyProducts;
            }

        }
    );

    const locations = useLiveQuery(
        async () => {
            try {
                setIsLocationsLoading(true);
                const Locations = await db.Locations.toArray();
                return Locations;
            } finally {
                setIsLocationsLoading(false);
            }
        }
    );

    const sales = useLiveQuery(
        async () => {
            const Sales = await db.Sales.toArray();
            return Sales;
        }
    );

    const transfers = useLiveQuery(
        async () => {
            const Transfers = await db.Transfers.toArray();
            return Transfers;
        }
    );

    const [
        getAllTransferRecords,
        {
            isLoading: isTransferLoading,
        }] = useLazyGetAllRecordsQuery();

    const [
        getAllSalesRecords, {
            isLoading: isSalesLoading,
        }] = useLazyGetAllRecordsQuery();

    useEffect(() => {
        if (transfers?.length === 0) {
            getAllTransferRecords({
                entity: `ShopifyReference/ShopifyTransfers`,
                entities: { OriginLocation: {}, DestinationLocation: {}, Status: {} },
                count: true,
                tag: SHOPIFY_TRANSFERS,
                allRecords: true,
            }).then((response) => {
                if (!response.error) {
                    db.Transfers.clear()
                    let time = new Date()
                    response?.data?.value?.forEach((record, i) => {
                        db.Transfers.add({
                            Id: i + 1,
                            SkuObject: record.SkuObject,
                            OriginLocation: record.OriginLocationDTO,
                            DestinationLocation: record.DestinationLocationDTO,
                            Status: record.StatusDTO,
                            CreatedAt: record.CreatedAt,
                            UpdatedAt: record.UpdatedAt,
                            lastSynced: time,
                        });
                    });
                }
            })
        }
        if (sales?.length === 0) {
            const vendorNames = (loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "client"
                ? JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject || '[]')
                : []
            ).map(vendor => vendor.Name);

            const filterQuery = `${vendorNames.map(name => `ProductVendor eq '${encodeURIComponent(name.replace(`'`, `''`))}'`).join(' or ')}`;
            getAllSalesRecords({
                entity: `Shopify/GetAllSalesByProductVariantFromShopify`,
                filter: filterQuery,
                count: true,
                allRecords: true,
                tag: SHOPIFY_QUANTITY_LOCATIONS,
            }).then((response) => {
                db.Sales.clear()
                let time = new Date()
                response?.data?.value?.forEach((sales, i) => {
                    db.Sales.add({
                        Id: i + 1, ProductId: sales.ProductId, ProductTitle: sales.ProductTitle, ProductVendor: sales.ProductVendor, VariantId: sales.VariantId, VariantTitle: sales.VariantTitle, VariantSKU: sales.VariantSKU, NetQuantity: sales.NetQuantity, GrossSale: sales.GrossSale, TotalTax: sales.TotalTax, TotalSales: sales.TotalSales, NetSales: sales?.NetSales, VariantQuantityDTOs: sales.VariantQuantityDTOs, lastSynced: time, TotalSupplementaryCommission: sales?.TotalSupplementaryCommission, TotalSupplementaryNetSale: sales?.TotalSupplementaryNetSale, TotalSupplementaryQuantity: sales?.TotalSupplementaryQuantity, SupplementarySalesDTOs: sales?.SupplementarySalesDTOs,
                    })
                })
            })
        }
        // eslint-disable-next-line
    }, [sales, transfers]);

    let visible = loginUser?.userRelationRequestDTO && loginUser?.userRelationRequestDTO?.status === 'Approve'
    let visibleBeforeApproval = loginUser?.userRelationRequestDTO && !loginUser?.userRelationRequestDTO?.status === 'Approve'
    return visible ? (
        <Row>
            <Col lg={6} sm={3} xs={6}>
                <DashboardLink to="/products" label="Products" lastSynced={products?.length && moment(products?.[0]?.lastSynced).calendar()} isLoading={isProdLoading} count={products?.length} onClick={() => db.AllShopifyProducts.clear()} />
            </Col>
            <Col lg={6} sm={3} xs={6}>
                <DashboardLink to="/receiving-list" label="Transfer" lastSynced={transfers?.length && moment(transfers?.[0]?.lastSynced).calendar()} isLoading={isTransferLoading} count={transfers?.length} onClick={() => db.Transfers.clear()} />
            </Col>
            <Col lg={6} sm={3} xs={6}>
                <DashboardLink to="/locations" label="Locations" lastSynced={locations?.length && moment(locations?.[0]?.lastSynced).calendar()} isLoading={isLocationsLoading} count={locations?.length} onClick={() => db.Locations.clear()} />
            </Col>
            <Col lg={6} sm={3} xs={6}>
                <DashboardLink to="/sales" label="Sales" lastSynced={sales?.length && moment(sales?.[0]?.lastSynced).calendar()} isLoading={isSalesLoading} count={sales?.length} onClick={() => db.Sales.clear()} />
            </Col>
        </Row>
    ) : !visibleBeforeApproval ? "" : <div className={`d-flex justify-content-center align-items-center`}><CircularProgress size={20} style={{ color: "white" }} /></div>
}


export default TotalBox