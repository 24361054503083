import { useEffect, useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { Formik } from "formik";
import * as Yup from "yup";
import { db } from "./db";
import { errorToast, successToast } from "../../../Element/toast";
import noImage from "../../../../images/noImage.png"
// import Modal from "../../../components/Modal";
import CustomInput from "../../../../shared/components/form/CustomInput";

const EditProductForm = ({ editDetails, setEditDetails, productQuantityArray, setIsUpdateModalOpen }) => {
    const [isProductOptions, setIsProductOptions] = useState([]);
    // const [isProductPopup, setIsProductPopup] = useState(false);
    const validationSchema = Yup.object().shape({
        Products: Yup.mixed().required("Please select or scan product."),
        Quantity: Yup.string().required("Please enter qauntity."),
    });

    const onSubmit = async (values, { resetForm }) => {
        // if (values?.Quantity <= editDetails?.productOption?.inventoryQuantity) {
        try {
            await db.Products.update(editDetails.id, { ...editDetails, quantity: parseInt(values.Quantity) });
            successToast(`Product successfully updated`);
            resetForm()
            clearForm()
            setIsUpdateModalOpen(false)
        } catch (error) {
            errorToast(`Failed to update: ${error}`);
        }
        // } else setIsProductPopup(true)
    }

    const clearForm = () => {
        setEditDetails({});
    }

    useEffect(() => {
        if (productQuantityArray && productQuantityArray?.length) {
            let a = productQuantityArray.map((prod) => {
                return {
                    id: prod.id,
                    productImage: prod.productImage,
                    inventoryQuantity: parseInt(prod.inventoryQuantity),
                    varinatId: prod.varinatId,
                    label: prod.productTitle + " - " + prod.varinatId,
                    value: prod.productTitle + " - " + prod.varinatId,
                    other: JSON.stringify(prod)
                }
            })
            setIsProductOptions(a)
        }
    }, [productQuantityArray])

    return (
        <>
            <Formik
                initialValues={{
                    Products: editDetails && Object.entries(editDetails)?.length ? editDetails : {},
                    Quantity: editDetails && Object.entries(editDetails)?.length ? editDetails.quantity : 1
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    const {
                        errors,
                        touched,
                        values,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                    } = formik;
                    return (
                        <>
                            <Row className="mt-lg-3">
                                <Col md={7}>
                                    {isProductOptions?.length &&
                                        <div>
                                            <Label for="ScanOrigin">
                                                Select product
                                                <font color="red"> *</font>
                                            </Label>
                                            <AsyncPaginate
                                                className={`lazySelect`}
                                                name="Products"
                                                value={values.Products}
                                                options={isProductOptions}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => {
                                                    if (option.id) {
                                                        return (<div className="d-flex justify-content-between">
                                                            <div>
                                                                <img src={option?.productImage ? option?.productImage : noImage} style={{ width: "50px", marginRight: "10px" }} alt="" />
                                                                {option.label}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                {option?.productOption?.inventoryQuantity}
                                                            </div>
                                                        </div>)
                                                    }
                                                }}
                                                isDisabled
                                            />
                                        </div>}
                                </Col>
                                <Col md={5}>
                                    <CustomInput
                                        label="Product Quantity"
                                        name="Quantity"
                                        type="number"
                                        fieldErrors={errors.Quantity}
                                        fieldTouched={touched.Quantity}
                                        fieldValue={values.Quantity}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        required
                                    />
                                </Col>
                            </Row>

                            <div className={`d-flex justify-content-around mt-3 `}>
                                <Button
                                    color="primary"
                                    onClick={handleSubmit}
                                    value="submit"
                                > Update Product
                                </Button>
                            </div>
                        </>
                    )
                }}
            </Formik >
            {/* {
                isProductPopup && (
                    <Modal
                        color="warning"
                        title="Warning"
                        message={
                            <div className="p-3">
                                <p color='orange'>You can not add more than {editDetails && editDetails?.id ? editDetails?.productOption?.inventoryQuantity : ""}.</p>
                            </div>
                        }
                        handleOkClick={() => setIsProductPopup(!isProductPopup)}
                        toggle={() => setIsProductPopup(!isProductPopup)}
                    />
                )
            } */}
        </>
    );
}

export default EditProductForm