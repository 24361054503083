import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useLiveQuery } from 'dexie-react-hooks';
import { CircularProgress } from '@material-ui/core';
import ReactTable from '../../shared/components/Table';
import { SHOPIFY_LOCATIONS } from '../../shared/tagFiles';
import { useLazyGetSimpleRecordsQuery } from '../../services/pcApi';
import { db } from './ProductScan/Dexie/db';

const AllLocations = () => {
    const [data, setData] = useState()
    const history = useHistory()
    const [getAllShopifyLocationRecords, { isLoading: isLocationsLoading }] = useLazyGetSimpleRecordsQuery();
    const locations = useLiveQuery(
        async () => {
            const Locations = await db.Locations.toArray();
            return Locations;
        }
    );
    const formatAddress = (addressData) => Object.entries(addressData).map(([_, value]) => value).join(" ");

    const columns = [
        {
            Header: "Id",
            accessor: "id",
            Cell: (({ value }) => {
                return value ?? "-"
            })
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: (({ value }) => {
                return value ?? "-"
            })
        },
        {
            Header: "Address",
            accessor: "variantSku",
            filter: false,
            Cell: (({ cell }) => {
                let value = cell?.row?.original
                let address = {
                    address1: value?.address1,
                    address2: value?.address2,
                    zip: value?.zip,
                    city: value?.city,
                    country: value?.country
                }
                return value ? formatAddress(address) : "-"
            })


        },
    ]

    useEffect(() => {
        setData(locations)
    }, [locations])

    useEffect(() => {
        if (locations?.length === 0) {
            getAllShopifyLocationRecords({
                entity: `Shopify/GetLocationDetailFromShopify`,
                tag: SHOPIFY_LOCATIONS,
            }).then((response) => {
                if (!response.error) {
                    db.Locations.clear()
                    let time = new Date()
                    response?.data?.items?.forEach((location) => {
                        db.Locations.add({ id: location.id, active: location.active, name: location.name, address1: location.address1, address2: location.address2, zip: location.zip, city: location.city, province: location.province, country: location.countryName, phone: location.phone, legacy: location.legacy, lastSynced: time });
                    })
                }
            })
        }
    }, [locations]);

    return (
        <>
            <div className='py-3'>
                <div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
                    <div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
                        <div className="card__title d-flex justify-content-between align-items-center mb-2"><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i>Location</h5>
                            <Button onClick={() => {
                                db.Locations.clear()
                            }}
                                disabled={isLocationsLoading}
                            >
                                {(isLocationsLoading) && (
                                    <CircularProgress color="inherit" size={18} className='mr-1' />
                                )}{" "}
                                Refresh</Button>
                        </div>
                        <ReactTable columns={columns} data={data?.length ? data : []} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllLocations