import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Table } from 'react-bootstrap';
import moment from 'moment/moment';
import { CircularProgress } from '@material-ui/core';
import ReactTable from '../../../shared/components/Table';
import { useLazyGetSimpleRecordsQuery } from '../../../services/pcApi';
import { SHOPIFY_ORDER_TAG } from '../../../shared/tagFiles';

const ShopifyOrderDetails = (props) => {
	const orderId = props?.location?.state?.orderData?.shopifyOrderId;
	const [getShopifyOrder, {
		data: shopifyOrderData,
		isLoading: isShopifyOrderLoading }
	] = useLazyGetSimpleRecordsQuery();
	const history = useHistory()

	const getTotal = () => shopifyOrderData?.lineItems?.reduce((sum, item) => sum + parseFloat(item?.price * item?.quantity), 0).toFixed(2) || 0
	const totalDiscounts = shopifyOrderData?.totalDiscounts?.toFixed(2) || 0.00
	const tax = shopifyOrderData?.totalTax?.toFixed(2) || 0.00
	const shippingPrice = shopifyOrderData?.shippingLines?.length !== 0 ? shopifyOrderData?.shippingLines[0]?.price?.toFixed(2) || 0.00 : 0.00

	let shippingAddress = {
		address1: shopifyOrderData?.shippingAddress?.address1,
		address2: shopifyOrderData?.shippingAddress?.address2,
		zip: shopifyOrderData?.shippingAddress?.zip,
		city: shopifyOrderData?.shippingAddress?.city,
		country: shopifyOrderData?.shippingAddress?.country
	}

	let billingAddress = {
		address1: shopifyOrderData?.billingAddress?.address1,
		address2: shopifyOrderData?.billingAddress?.address2,
		zip: shopifyOrderData?.billingAddress?.zip,
		city: shopifyOrderData?.billingAddress?.city,
		country: shopifyOrderData?.billingAddress?.country
	}

	const columns = [
		{
			Header: "Title",
			accessor: "title",
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "SKU",
			accessor: "sku",
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "Vendor",
			accessor: "vendor",
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "Quantity",
			accessor: "quantity",
			type: "Number",
			Cell: ({ value }) => {
				return value ? value : "-"
			}
		},
		{
			Header: "Price",
			accessor: "price",
			type: "Number",
			Cell: ({ value }) => {
				return value ? `$${value.toFixed(2)}` : "-";
			}
		},
	];
	useEffect(() => {
		if (orderId) {
			getShopifyOrder({
				entity: `Shopify/GetOrderByIdFromShopify?orderId=${orderId}`,
				tag: SHOPIFY_ORDER_TAG,
			});
		}
	}, [orderId])

	return shopifyOrderData?.id ? (
		<>
			<div className='py-3'>
				<div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
					<div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
						<div className="card__title d-flex justify-content-between align-items-center mb-2"><div className='d-flex align-items-baseline'><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i> Order</h5></div>
							<a href={shopifyOrderData?.orderStatusUrl} target="_blank"><Button>View more</Button></a>
						</div>
						<div style={{ border: "1px solid rgba(0, 0, 0, .125)" }}>
							<Row className="chart-container">
								<Col lg={12} className="d-flex justify-content-between">
									<p style={{ margin: "5px 10px" }}>
										<span className='order-details-text '>#{`${shopifyOrderData?.orderNumber}` || "-"}</span>
									</p>
									<p style={{ margin: "5px 10px" }}>
										<span className={`order-details-text badge-success px-2 ${shopifyOrderData?.financialStatus === "paid" ? "bg-success " : 'bg-danger'}`} style={{ borderRadius: "8px", padding: "5px 10px", textTransform: "capitalize" }}>
											{shopifyOrderData?.financialStatus || "-"}</span>
									</p>
								</Col>
							</Row>
							<Row className="chart-container">
								<Col lg={6} className="order-details-container">
									{(shopifyOrderData?.customer?.firstName || shopifyOrderData?.customer?.lastName) ? <p style={{ margin: "5px 10px" }}>
										<span className='order-details-text'><b>Customer Name:</b></span>
										<span className='order-details-text ml-2'>{`${shopifyOrderData?.customer?.firstName} ${shopifyOrderData?.customer?.lastName}`}</span>
									</p> : ""}
									<p style={{ margin: "5px 10px" }}>
										<span className='order-details-text'><b>Processed At:</b></span>
										<span className='order-details-text ml-2'>{moment(shopifyOrderData?.processedAt).format('MM-DD-YYYY h: mm a') || "-"}</span>
									</p>
									{shopifyOrderData?.email ? <p style={{ margin: "5px 10px" }}>
										<span className='order-details-text'><b>Customer Email:</b></span>
										<span className='order-details-text ml-2'>{shopifyOrderData?.email}</span>
									</p> : ""}
									<p style={{ margin: "5px 10px" }}>
										<span className='order-details-text'><b>Status:</b></span>
										<span className={`order-details-text ml-2 ${shopifyOrderData?.fulfillmentStatus === null && "text-danger"}`}>{
											shopifyOrderData?.fulfillmentStatus === null ? "Unshipped" :
												shopifyOrderData?.fulfillmentStatus === "fulfilled" ? "Shipped" :
													shopifyOrderData?.fulfillmentStatus === "partial" ? "Partial" : "Any" || "-"}</span>
									</p>
									{shopifyOrderData?.billingAddress && Object.entries(shopifyOrderData?.billingAddress)?.length ? <p style={{ margin: "5px 10px" }}>
										<span className='order-details-text'><b>Billing Address:</b></span>
										<span className='order-details-text ml-2'>{Object.entries(billingAddress).map(([_, value]) => value).join(" ")}</span>
									</p> : ""}
									{shopifyOrderData?.shippingAddress && Object.entries(shopifyOrderData?.shippingAddress)?.length ? <p style={{ margin: "5px 10px" }}>
										<span className='order-details-text'><b>Shipping Address:</b></span>
										<span className='order-details-text ml-2'>{Object.entries(shippingAddress).map(([_, value]) => value).join(" ")}</span>
									</p> : ""}
								</Col>
								<Col lg={6} className="order-details-container">
									<Table>
										<tbody>
											<tr>
												<td><b>Sub Total</b></td>
												<td className="text-right">{`$${getTotal()}`}</td>
											</tr>
											<tr>
												<td><b>Discount</b></td>
												<td className="text-right">- {`$${totalDiscounts}`}
													<div>
														<hr className='w-50 mb-0' style={{ float: 'right' }} />
													</div>
												</td>
											</tr>
											<tr>
												<td><b> </b></td>
												<td className="text-right">{`$${(getTotal() - +totalDiscounts).toFixed(2)}`}</td>
											</tr>
											<tr>
												<td><b>Shipping</b></td>
												<td className="text-right">+ {`$${shippingPrice}`}
													<div>
														<hr className='w-50 mb-0' style={{ float: 'right' }} />
													</div>
												</td>
											</tr>
											<tr>
												<td><b>Net Sales</b></td>
												<td className="text-right">{`$${(getTotal() - +totalDiscounts + +shippingPrice + +tax).toFixed(2)}`}</td>
											</tr>
										</tbody>
									</Table>
								</Col>
							</Row>
						</div>
						<div className='py-3'>
							<div>
								<div className='p-3' style={{ border: "1px solid rgba(0, 0, 0, .125)" }}>
									<div className="card__title"><h5 className="bold-text"> Product List</h5></div>
									<ReactTable columns={columns} data={shopifyOrderData?.lineItems?.length ? shopifyOrderData.lineItems : []} disabledGlobalSearch />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	) : isShopifyOrderLoading ? <div className="d-flex justify-content-center "><CircularProgress /></div> : "Something went wrong";
}

export default ShopifyOrderDetails
