import { configureStore } from '@reduxjs/toolkit'
import { pcApi } from '../services/pcApi'
import sliceReducer from "../redux/reducers/Slice";

export const store = configureStore({
    reducer: {
        [pcApi.reducerPath]: pcApi.reducer,
        data: sliceReducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(pcApi.middleware)
})
