import Dexie from 'dexie';

export const db = new Dexie('myDb34');
db.version(1).stores({
    Shipments: '++id, originName, originId, originScanTime, destinationName, destinationId, destinationScanTime, status, createdAt',
    Products: '++id, variantid, shipmentId, name, quantity, other, createdAt',
    Locations: 'id, active, name, address1, address2, zip, city, province, country, phone, legacy, lastSynced',
    AllShopifyProducts: '++id, Id, varinatId, productImage, productId, productTitle, productVendor, productStatus, inventoryItemId, variantTitle, variantPrice, variantSku, inventoryQuantity, inventoryTrackable, barcode, lastSynced',
    ReceivingLocations: '++id, receivingName, receivingId, receivingScanTime, status, createdAt',
    ReceivingProducts: '++id, variantid, shipmentId, name, quantity, other, createdAt',
    Sales: '++Id, ProductId, ProductTitle, ProductVendor, VariantId, VariantTitle, VariantSKU, NetQuantity, GrossSale, TotalTax, TotalSales, VariantQuantityDTOs, lastSynced, TotalSupplementaryCommission, TotalSupplementaryNetSale, TotalSupplementaryQuantity, SupplementarySalesDTOs',
    Transfers: 'Id, SkuObject, OriginLocation, DestinationLocation, Status, CreatedAt, UpdatedAt, IsDeleted, lastSynced',
    SalesByQuarterly: 'Id, ProductId, ProductTitle, ProductVendor, VariantId, VariantTitle, VariantSKU, NetQuantity, Price, GrossSale, TotalTax, TotalSales, QuarterQuantityDTOs,TotalDiscounts,TotalReturns,TotalCommission,NetSales,RefundProductVariantDTOs',
    ShopifyOrders: 'Id, fulfillmentStatus, orderNumber, processedAt, shopifyOrderId, LastSynced',
    BackerKitOrders: 'Id, CustomerId, RewardId, OrderStatusId, PledgeStatusId, BackerKitOrderId, Platform, IsEnvelope, ChargeToken, PledgeAmount, PledgeAt, ChargedAt, SurveyAnsweredAt, OrderStatusDTO, CustomerDTO, PledgeStatusDTO, BackerKitFulfillOrderSkuDTO, LastSynced',
});


