import React, { useState, useEffect } from "react";
import { Button, Table } from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_TAG } from "../../shared/tagFiles";
import { useLazyGetRecordsByIdQuery } from "../../services/pcApi";
import { CircularProgress } from "@material-ui/core";
import EditProfileModal from "./Components/EditProfileModal";
import AddModal from "../../shared/components/AddModal";

const Profile = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const history = useHistory()
  const user = useSelector(state => state?.data?.user);
  const [getUser, { data: userData, isLoading: isUserLoading }] = useLazyGetRecordsByIdQuery();

  const vendor = () => {
    if (user?.relationDTO?.name.toLowerCase() === "client") {
      const vendorArray = JSON.parse(user?.userRelationRequestDTO?.vendorObject);
      return <tr>
        <td className='sales-details-text'><b>Vendors:</b></td>
        <td className='sales-details-text'>
          <ol>
            {vendorArray?.map((item) => (
              <li>
                {item.Name}
              </li>
            ))}
          </ol>
        </td>
      </tr>
    } else return ""
  }

  useEffect(() => {
    if (user?.id) {
      getUser({
        entity: `/Auth/User`,
        id: user?.id,
        entities: {
          Entity: {}, UserMedias: {}, UserRelationRequests: {}, "UserMedias.Media": {}, Contacts: {}
        },
        tag: USER_TAG,
      });
    }
  }, [user]);

  useEffect(() => {
    if (userData && Object.entries(userData)?.keys && !isUserLoading) setUserDetail(userData)
  }, [userData])

  return (!isUserLoading && user?.id) ? (
    <>
      <div className='py-3'>
        <div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
          <div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
            <div className="d-flex justify-content-between align-items-center mb-2"><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i>Profile</h5>
            </div>
            <div className="col col-lg-12 mb-4 mb-lg-0">
              <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                <div className="row g-0" style={{ height: "100%", minHeight: "350px" }}>
                  <div className="col-md-4 gradient-custom text-center text-white profile-image-main-div">
                    <div className="profile-image-div">
                      {userDetail?.UserMediaDTO?.length > 0 ? <img
                        src={userDetail?.UserMediaDTO?.[0]?.MediaDTO?.Url}
                        alt=""
                        className="img-fluid my-4 profile-image"
                      /> :
                        <div className="img-fluid my-4 profile-image profile-image-letter">
                          <span>{userDetail?.Name?.charAt(0).toUpperCase()}</span>
                        </div>}
                    </div>
                    <div className="profile-image-button">
                      <Button
                        className="edit-profile-button btn btn-primary my-4 site-button"
                        onClick={() => setIsEditMode(!isEditMode)}
                      >
                        Edit Profile
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="col g-5 p-4 ">
                      <Table bordered responsive >
                        <tbody className="profile-table">
                          <tr>
                            <td className='sales-details-text'><b>Name:</b></td>
                            <td className='sales-details-text'>{userDetail?.Name ?? "-"}</td>
                          </tr>
                          <tr>
                            <td className='sales-details-text'><b>Email:</b></td>
                            <td className='sales-details-text'>{userDetail?.Email ?? "-"}</td>
                          </tr>
                          <tr>
                            <td className='sales-details-text'><b>Description:</b></td>
                            <td className='sales-details-text'>{userDetail?.Description ?? "-"}</td>
                          </tr>
                          <tr>
                            <td className='sales-details-text'><b>Contact No:</b></td>
                            <td className='sales-details-text'>{userDetail?.ContactDTO?.[0]?.PhoneNumber ?? "-"}</td>
                          </tr>
                          <tr>
                            <td className='sales-details-text'><b>Relation:</b></td>
                            <td className='sales-details-text'>{user?.relationDTO?.name}</td>
                          </tr>
                          {vendor()}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEditMode && (
        <AddModal
          toggle={() => setIsEditMode(!isEditMode)}
          title="Edit Profile"
          xxl
          color="rgb(182, 114, 12)"
          message={
            <EditProfileModal
              setIsAddModalOpen={setIsEditMode}
              editData={userData}
              isAddModalOpen={isEditMode}
              toggal={() => setIsEditMode(!isEditMode)}
            />
          }
        />
      )}
    </>
  ) : <div className="d-flex justify-content-center"><CircularProgress /></div>
};

export default Profile;
