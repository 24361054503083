import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import ReactTable from '../../../shared/components/Table';
import { db } from '../ProductScan/Dexie/db';
import { useAddMutation } from '../../../services/pcApi';
import { successToast } from '../../Element/toast';

const BackerKitOrderDetails = (props) => {
	const groupDetailsPage = props.tag === "grp"
	const data = props?.location?.state?.orderData;
	const history = useHistory()
	const [skuData, setSkuData] = useState([])
	const [exportPDF, { isLoading: isExportPDFLoading }] = useAddMutation();
	const getSubTotal = () => data?.BackerKitFulfillOrderSkuDTO?.reduce((sum, item) => sum + parseFloat(item?.Price * item?.Quantity), 0).toFixed(2) || 0
	const getProductDetails = (sku) => products?.filter(item => item.variantSku === sku)

	let shippingAddress = {
		address1: data?.CustomerDTO?.AddressDTO?.Line1,
		address2: data?.CustomerDTO?.AddressDTO?.Line2,
		zip: data?.CustomerDTO?.AddressDTO?.ZipCode,
	}

	const products = useLiveQuery(
		async () => {
			const AllShopifyProducts = await db.AllShopifyProducts.toArray();
			return AllShopifyProducts;
		}
	);

	const getWeightInOz = (weight, weightUnit) => weightUnit == 'lb' ? (weight * 16).toFixed(2) : +weight.toFixed(2) || 0

	// const getTotalWeight = () => skuData?.reduce((sum, item) => {
	// 	let product = getProdutDetails(item?.Name)
	// 	return sum + parseFloat((getWeightInOz(product?.weight, product?.weightUnit) || 0) * item?.Quantity)
	// }, 0)

	const columns = [
		{
			Header: "Product",
			filter: false,
			Cell: (val) => {
				return <span>
					{getProductDetails(val?.row?.original?.SkuDTO?.Name)?.[0]?.productTitle};
				</span>
			},
		},
		{
			Header: "SKU",
			accessor: "SkuDTO.Name",
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "Vendor",
			filter: false,
			Cell: (val) => {
				return getProductDetails(val?.row?.original?.SkuDTO?.Name)?.[0]?.productVendor ?? "-";
			},
		},
		{
			Header: "Quantity",
			accessor: "Quantity",
			type: "Number",
			Cell: ({ value }) => {
				return value ? value : "-"
			}
		},
		{
			Header: "Price",
			accessor: "Price",
			type: "Number",
			Cell: ({ value }) => {
				return value ? `$${value.toFixed(2)}` : "-";
			}
		},
		{
			Header: "Total Price",
			type: "Number",
			filter: false,
			Cell: (val) => {
				const price = val.row.original.Price ?? 0;
				const quantity = val.row.original.Quantity ?? 0;

				const totalPrice = price * quantity;

				return `$${totalPrice.toFixed(2)}`;
			}
		}

	];

	const grpColumns = [
		{
			Header: "Product Name",
			accessor: "ProductName",
			Cell: ((value) => {
				return getProductDetails(value?.row?.original?.Name)?.[0]?.productTitle || "-"
			})
		},
		{
			Header: "SKU",
			accessor: "Name",
			Cell: (({ value }) => {
				return value ?? "-"
			})
		},
		{
			Header: "Vendor",
			Cell: ((value) => {
				return getProductDetails(value?.row?.original?.Name)?.[0]?.productVendor || "-"
			})
		},
		{
			Header: "Quantity",
			accessor: "Quantity",
			Cell: (({ value }) => {
				return value ?? "-"
			})
		},
		{
			Header: "Total Quantity",
			filter: false,
			Cell: ((value) => {
				return (value?.cell?.row?.original?.Quantity || 0) * data?.TotalOrder
			})
		},
		{
			Header: "Weight",
			accessor: "Weight",
			Cell: ((value) => {
				return value ? `${getWeightInOz(value?.cell?.row?.original?.Weight, value?.cell?.row?.original?.WeightUnit)} oz` : "-"
			})
		},
		{
			Header: "Total Weight",
			filter: false,
			Cell: ((value) => {
				return value ? +value?.cell?.row?.original?.Weight * value?.cell?.row?.original?.Quantity : "-"
			})
		},
		{
			Header: "Price",
			accessor: "Price",
			Cell: (({ value }) => {
				return value ? `$${parseFloat(value).toFixed(2)}` : "-";
			})
		},
	]

	const getProdutDetails = (item) => {
		return products?.filter(products => products?.variantSku === item)?.[0]
	}

	const base64ToBlob = (base64String, contentType) => {
		const sliceSize = 1024;
		const byteCharacters = atob(base64String);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};

	const exportPDf = () => {
		let payload = {
			GroupNumber: data?.GroupNumber,
			GroupName: data?.GroupName,
			TotalOrder: data?.TotalOrder,
			OrderDetailDTOs: data?.OrderDetailDTOs,
			SkuDetailDTOs: skuData,
		}

		exportPDF({
			entity: `BackerKit_Fulfill/ExportGroupingOrderDetailAsPDF`,
			data: payload,
		}).then((response) => {
			if (!response.error) {
				const blob = base64ToBlob(response.data, 'application/pdf');
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = data?.GroupName;
				link.click();
				window.URL.revokeObjectURL(url);
				successToast("PDF succefully download")
			}
		})
	}

	useEffect(() => {
		let arr = []
		if (data && Object.entries(data)?.length && products && Object.entries(products)?.length) {
			data?.SkuDetailDTOs?.filter((item) => {
				arr.push({
					...item,
					"ProductName": getProdutDetails(item.Name)?.productTitle,
					"Name": item?.Name,
					"Vendor": getProdutDetails(item.Name)?.productVendor,
					"Quantity": item?.Quantity,
					"Weight": getWeightInOz(getProdutDetails(item.Name)?.weight, getProdutDetails(item.Name)?.weightUnit),
					"WeightUnit": "oz",
					"TotalWeight": getProdutDetails(item.Name)?.weight || 0 * item?.Quantity || 0 * data?.TotalOrder || 0,
					"Grams": getProdutDetails(item.Name)?.grams,
					"Price": item?.Price,
				})
			})
		}
		setSkuData(arr)
	}, [data, products])

	return useMemo(() => {
		return data ? (
			<>
				<div className='py-3'>
					<div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
						<div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
							<div className="card__title d-flex justify-content-between align-items-center mb-2"><div className='d-flex align-items-baseline'><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i> Order</h5></div>
								{groupDetailsPage && <Button onClick={exportPDf} disabled={isExportPDFLoading}>
									{isExportPDFLoading && <CircularProgress color="inherit" size={18} className='mr-1' />}
									Export PDF
								</Button>}
							</div>
							{!groupDetailsPage ?
								<div style={{ border: "1px solid rgba(0, 0, 0, .125)" }}>
									<Row>
										<Col lg={12} className="d-flex justify-content-between">
											<p style={{ margin: "5px 10px" }}>
												<span className='order-details-text '>#{`${data?.BackerKitOrderId}` || "-"}</span>
											</p>
											<p style={{ margin: "5px 10px" }}>
												<span className={`order-details-text badge-success text-capitalize px-2 ${data?.PledgeStatusDTO.Name === "collected" ? "bg-success" : 'bg-danger'}`} style={{ borderRadius: "8px", padding: "5px 10px", }}>
													{data?.PledgeStatusDTO.Name || "-"}</span>
											</p>
										</Col>
									</Row>
									<Row>
										<Col lg={6} className="order-details-container">
											{data?.CustomerDTO?.Name ? <p style={{ margin: "5px 10px" }}>
												<span className='order-details-text'><b>Customer Name:</b></span>
												<span className='order-details-text ml-2'>{data?.CustomerDTO?.Name}</span>
											</p> : ""}
											<p style={{ margin: "5px 10px" }}>
												<span className='order-details-text'><b>Survey Answered At:</b></span>
												<span className='order-details-text ml-2'>{data?.SurveyAnsweredAt ? moment(data?.SurveyAnsweredAt).format('MM-DD-YYYY h: mm a') : "-"}</span>
											</p>
										</Col>
										<Col lg={6} className="order-details-container">
											{data?.CustomerDTO.Email ? <p style={{ margin: "5px 10px" }}>
												<span className='order-details-text'><b>Customer Email:</b></span>
												<span className='order-details-text ml-2'>{data?.CustomerDTO.Email}</span>
											</p> : ""}
											<p style={{ margin: "5px 10px" }}>
												<span className='order-details-text'><b>Sub Total:</b></span>
												<span className='order-details-text ml-2'>${getSubTotal()}</span>
											</p>
										</Col>
									</Row>
									{data?.CustomerDTO?.AddressDTO && Object.entries(data?.CustomerDTO?.AddressDTO)?.length ? <Row>
										<Col lg={12} className="order-details-container">
											<p style={{ margin: "5px 10px" }}>
												<span className='order-details-text'><b>Billing Address:</b></span>
												<span className='order-details-text ml-2'>{Object.entries(shippingAddress).map(([_, value]) => value).join(" ")}</span>
											</p>
										</Col>
									</Row> : ""}
								</div> : ""}
							<div className='py-3'>
								<div>
									<div className='p-3' style={{ border: "1px solid rgba(0, 0, 0, .125)" }}>
										<div className="card__title d-flex justify-content-between">
											<h5 className="bold-text"> {groupDetailsPage ? data?.GroupName : "Product List"}</h5>
											{groupDetailsPage ? <h5 className="bold-text">Total Orders: {data?.TotalOrder}</h5> : ""}
										</div>
										<ReactTable columns={groupDetailsPage ? grpColumns : columns} data={groupDetailsPage ? skuData : data?.BackerKitFulfillOrderSkuDTO || []} disabledGlobalSearch />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		) : <div className="d-flex justify-content-center "><CircularProgress /></div>;
	}, [data, skuData, isExportPDFLoading])
}

export default BackerKitOrderDetails
