import { useLiveQuery } from "dexie-react-hooks";
import { db } from "./db";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import AddProductForm from "./Form";
import Modal from "../../../components/Modal";
import EditProductForm from "./EditForm";
import AddModal from "../../../../shared/components/AddModal";

const RenderData = ({ productQuantityArray, scanOrigin }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isUpdateModelOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [editDetails, setEditDetails] = useState({});
    const Products = useLiveQuery(
        async () => {
            const Products = await db.Products.toArray() || [];
            return Products;
        },
    );

    const deleteFriend = async (id) => {
        await db.Products.delete(id);
        setIsDeleteModalOpen(!isDeleteModalOpen)
    };

    const updateProduct = (product) => {
        let updatedEditDetails = {
            ...product,
            label: product?.name,
            value: product?.name,
            productImage: JSON.parse(product?.other)?.productImage,
            productOption: productQuantityArray.filter(item => item.varinatId === product.variantid)?.[0] ?? []
        };
        setEditDetails(updatedEditDetails);
        setIsUpdateModalOpen(true)
    }
    return (
        <>
            <AddProductForm editDetails={editDetails} setEditDetails={setEditDetails} productQuantityArray={productQuantityArray} />

            <Table className="mt-3">
                <thead className="">
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Products?.map((product, i) => {
                        return (
                            <tr key={i}>
                                <td>{product?.id}</td>
                                <td>{product?.name}</td>
                                <td>
                                    {product?.quantity} <br />
                                    {product?.quantity <= productQuantityArray?.filter(vr => vr?.varinatId === product?.variantid)?.[0]?.inventoryQuantity ? "" :
                                        <span> Only {productQuantityArray.length ? productQuantityArray.filter(vr => vr.varinatId === product.variantid)?.[0]?.inventoryQuantity : '0'} items available at {scanOrigin.value}</span>
                                    }
                                </td>
                                <td className="d-flex justify-content-around">
                                    <Button
                                        onClick={() => updateProduct(product)}
                                        className="me-2"
                                    ><i className="fa fa-pencil"></i></Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            setIsDeleteModalOpen(!isDeleteModalOpen)
                                            setSelectedRecord(product)
                                        }}
                                        className="ms-2"
                                    > <i className="fa fa-trash"></i></Button>
                                </td>
                            </tr>
                        )
                    }
                    )}
                    {Products?.length === 0 && <li>No Products yet!</li>}
                </tbody>
            </Table >

            {isDeleteModalOpen && (
                <Modal
                    color="danger"
                    title="Delete"
                    message={`Are you sure you want to delete ${selectedRecord.name}?`}
                    handleOkClick={() => deleteFriend(selectedRecord.id)}
                    toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                />
            )
            }
            {isUpdateModelOpen && (
                <AddModal
                    toggle={() => {
                        setIsUpdateModalOpen(!isUpdateModelOpen);
                    }}
                    color="success"
                    title={"Edit Product"}
                    header
                    xxl
                    btn="Success"
                    message={
                        <div>
                            <EditProductForm
                                editDetails={editDetails ? editDetails : {}}
                                setEditDetails={setEditDetails}
                                productQuantityArray={productQuantityArray}
                                setIsUpdateModalOpen={setIsUpdateModalOpen}
                                isUpdateModelOpen={isUpdateModelOpen}
                            />
                        </div>
                    }
                />
            )}
        </>
    );
}

export default RenderData