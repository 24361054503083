import React, { useEffect, useState } from "react";
import { Form, Formik } from 'formik';
import { SpinnerCircular } from 'spinners-react';
import * as Yup from "yup";
import { SURVEY_TAG } from "../../shared/tagFiles";
import { useLazyGetRecordsByIdQuery, useAddMutation } from "../../services/pcApi";
import CustomFormSubmit from "../components/CustomFormSubmit";
import { createYupSchema } from "../components/YupSchemaCreator";
import CustomForm from "../components/CustomForm";

const Contact = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [formData, setFormData] = useState();
    const [addRecord, { isLoading: isAddingRecord }] = useAddMutation();
    const [successHandler, setSuccessHandler] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const [initialValues, setInitialValues] = useState({});

    const [
        getRecords,
        {
            data: formDataList,
            isLoading
        }
    ] = useLazyGetRecordsByIdQuery();
    useEffect(() => {
        getRecords({
            entity: `Comm/GetQuestionsBySurvey`,
            id: 1,
            tag: SURVEY_TAG
        })
    }, [])

    const findInputType = (type) => {
        switch (type) {
            case 'string':
                return 'text'
            case 'email':
                return 'email'
            case 'number':
                return 'text'
            case 'Multiline Text':
                return 'textarea'
            default:
                break
        }
    }
    const findInputIcon = (type) => {
        switch (type) {
            case 'string':
                return 'user'
            case 'number':
                return 'mobile'
            case 'email':
                return 'email'
            case 'file':
                return 'file'
            case 'Multiline Text':
                return 'agenda'
            case 'checkbox':
                return 'checkbox'
            default:
                break
        }
    }


    useEffect(() => {
        formDataList && setFormData(formDataList)
    }, [formDataList])

    useEffect(() => {
        if (formData) {
            const response = formData.questions.reduce((obj, val) => {
                return { ...obj, [`${val.questionType.name.replace(/\s/g, '')}${val.id}`]: "" }
            }, {})
            setInitialValues(response)
        }
    }, [formData])

    const yupSchema = formData && formData.questions.reduce(createYupSchema, {});
    const validationSchema = Yup.object().shape(yupSchema);

    const submitForm = async (values) => {
        CustomFormSubmit(values, formData, addRecord, setSuccessHandler, successHandler, userInfo)
    }

    return (
        <>
            <div className="full-section">
                <div className="dlab-bnr-inr overlay-primary-dark contact-page">
                    <div className="container">
                        <div className=" col-xs-8">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={submitForm}
                                enableReinitialize={true}
                            >
                                {(formik) => {
                                    const { errors, handleBlur, handleChange } = formik;
                                    return (
                                        <Form className="inquiry-form dzForm d-flex justify-content-center align-content-center consultation-form" action="script/contact.php">
                                            {
                                                isLoading ? <SpinnerCircular color='white' thickness={100} secondaryColor="black" /> :
                                                    <div>
                                                        <div className="dzFormMsg"></div>
                                                        <h3 className="box-title m-t0 m-b10">{formData && formData.survey.name}<span className="bg-primary"></span></h3>
                                                        <p>{formData && formData.survey.description}</p>
                                                        <div className="row">
                                                            {
                                                                formData && formData.questions.map((survey, index) => {
                                                                    const inputType = findInputType(survey.questionType.name);
                                                                    const inputIcon = findInputIcon(survey.questionType.name);
                                                                    var surveyName = `${survey.questionType.name}${survey.id}`.replace(/\s/g, '')

                                                                    return (
                                                                        <div key={index} className={(survey.questionType.name == 'email' || survey.questionType.name == 'Multiline Text') ? 'col-lg-12' : 'col-lg-6'}>
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <CustomForm
                                                                                        key={index}
                                                                                        dataValue={surveyName}
                                                                                        survey={survey}
                                                                                        errors={errors}
                                                                                        questionName={survey.name}
                                                                                        setIsFocused={setIsFocused}
                                                                                        isFocused={isFocused}
                                                                                        inputType={inputType}
                                                                                        handleChange={handleChange}
                                                                                        handleBlur={handleBlur}
                                                                                        label={false}
                                                                                        inputIcon={inputIcon}
                                                                                        questionDescription={surveyName}

                                                                                    />
                                                                                </div>
                                                                                {errors[surveyName] && <small className='text-danger'>{errors[surveyName]}</small>}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <div className="col-lg-12">
                                                                <span>
                                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                                        <button type="submit" name="submit" value="Submit" disabled={isAddingRecord} className="site-button button-lg d-flex justify-content-center"> {
                                                                            isAddingRecord && <SpinnerCircular color='black' size={20} thickness={100} className="mr-10" secondaryColor="white" />
                                                                        }  <span className='submit-button'>Apply for a Consultation Slot</span></button>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;