import React, { useState } from 'react'
import { Button, FormControl } from 'react-bootstrap';
import { Label } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import { CircularProgress } from '@material-ui/core';
import moment from 'moment/moment';
import { useLazyGetAllRecordsQuery } from '../../services/pcApi';
import { SHOPIFY_QUANTITY_LOCATIONS } from '../../shared/tagFiles';
import { db } from './ProductScan/Dexie/db';
import { useLiveQuery } from 'dexie-react-hooks';
import ReactTable from '../../shared/components/Table';
import "react-datepicker/dist/react-datepicker.css";

const Sales = () => {
	const [isSalesDexieLoading, setIsSalesDexieLoading] = useState(false);
	const [dataWithDate, setDataWithDate] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const history = useHistory();
	const loginUser = useSelector(state => state?.data?.user)

	const column = [
		{
			Header: "Id",
			accessor: "Id",
			type: "Number",
		},
		{
			Header: "Product",
			accessor: "ProductTitle",
			filter: true,
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "Vendor",
			accessor: "ProductVendor",
			filter: true,
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "Quantity",
			accessor: "NetQuantity",
			type: "Number",
			filter: true,
			Cell: data => data?.value ? data.value + data?.row?.original?.TotalSupplementaryQuantity : "-",
		},
		{
			Header: "Sales",
			accessor: "GrossSale",
			type: "Number",
			filter: true,
			Cell: data => `$${(data?.row?.original?.NetSales + data?.row?.original?.TotalSupplementaryNetSale).toFixed(2) || "0"}`
		},
	];

	const handleShowDetails = (data) => {
		history.push({
			pathname: "/sales-details",
			state: {
				data: data,
			},
		});
	};

	const sales = useLiveQuery(
		async () => {
			try {
				setIsSalesDexieLoading(true);
				const Sales = await db.Sales.toArray();
				return Sales;
			} finally {
				setIsSalesDexieLoading(false);
			}
		}
	);

	const [
		getAllSalesRecords, {
			isLoading: isSalesLoading,
		}] = useLazyGetAllRecordsQuery();

	const onRefresh = (refresh) => {
		const vendorNames = (loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "client"
			? JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject || '[]')
			: []
		).map(vendor => vendor.Name);
		const filterQuery = `${vendorNames.map(name => `ProductVendor eq '${encodeURIComponent(name.replace(`'`, `''`))}'`).join(' or ')}`;
		getAllSalesRecords({
			entity: `Shopify/GetAllSalesByProductVariantFromShopify`,
			filter: filterQuery,
			count: true,
			allRecords: true,
			afterQueryWithAllRecords: startDate ? `startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}` : '',
			tag: SHOPIFY_QUANTITY_LOCATIONS,
		}).then((response) => {
			if (!response.error) {
				if (refresh) {
					db.Sales.clear()
					let time = new Date()
					response?.data?.value?.forEach((sales, i) => {
						db.Sales.add({
							Id: i + 1, ProductId: sales.ProductId, ProductTitle: sales.ProductTitle, ProductVendor: sales.ProductVendor, VariantId: sales.VariantId, VariantTitle: sales.VariantTitle, VariantSKU: sales.VariantSKU, NetQuantity: sales.NetQuantity, GrossSale: sales.GrossSale, TotalTax: sales.TotalTax, TotalSales: sales.TotalSales, NetSales: sales?.NetSales, VariantQuantityDTOs: sales.VariantQuantityDTOs, lastSynced: time, TotalSupplementaryCommission: sales?.TotalSupplementaryCommission, TotalSupplementaryNetSale: sales?.TotalSupplementaryNetSale, TotalSupplementaryQuantity: sales?.TotalSupplementaryQuantity, SupplementarySalesDTOs: sales?.SupplementarySalesDTOs,
						})
					})
					setIsSalesDexieLoading(false)
				} else {
					let data = response?.data?.value?.map((item, i) => {
						return {
							...item,
							Id: i + 1
						}
					})
					setIsSalesDexieLoading(false)
					setDataWithDate(data)
				}
			}
		})
	}

	return (
		<>
			<div className='py-3'>
				<div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
					<div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
						<div className="card__title d-flex justify-content-between align-items-center mb-2"><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i> Sales</h5>
							<Button onClick={() => onRefresh(true)} disabled={isSalesLoading}>
								{(isSalesLoading) && (
									<CircularProgress color="inherit" size={18} className='mr-1' />
								)}{" "}
								Refresh</Button>
						</div>
						<div>
							{((sales && sales.length) || (dataWithDate && dataWithDate.length)) ?
								<ReactTable columns={column} data={(dataWithDate?.length ? dataWithDate ?? [] : sales ?? [])} isLoading={(isSalesDexieLoading || isSalesLoading)} hasHandleShow handleShowDetails={handleShowDetails}
									ExtraStuff={() => {
										return <>
											<div className='select-date-div'>
												<div className='d-flex '>
													<div className='d-flex align-items-center mr-2'>
														<Label className='mb-0'>Start Date:- </Label>
														<FormControl
															id="startDate"
															placeholder="Select Start Date"
															className="form-control"
															as={DatePicker}
															selected={startDate}
															autoComplete="off"
															onChange={(date) => {
																setStartDate(date);
																if (!endDate) setEndDate(moment().format('MM-DD-YYYY'));
															}}
														/>
													</div>
													<div className='d-flex align-items-center mr-2'>
														<Label className='mb-0'>End Date:- </Label>
														<FormControl
															id="endDate"
															placeholder="Select End Date"
															className="form-control"
															as={DatePicker}
															selected={endDate}
															autoComplete="off"
															onChange={(date) => {
																setEndDate(date);
																if (!startDate) setStartDate(moment().subtract(1, 'years').format('MM-DD-YYYY'));
															}}
														/>
													</div>
												</div>
												<div className='d-flex justify-content-around mt-lg-0 mt-2 '>
													<Button
														onClick={() => {
															setStartDate(0);
															setEndDate(0);
															setDataWithDate([]);
														}}
														className="modal_ok mr-md-1"
														color="success"
														disabled={!(startDate && endDate) || isSalesLoading}
													>
														Clear
													</Button>
													<Button
														disabled={!(startDate && endDate) || isSalesLoading}
														onClick={() => {
															setIsSalesDexieLoading(true);
															onRefresh();
														}}
														className="modal_ok"
													>
														{(isSalesLoading) && (
															<CircularProgress color="inherit" size={18} className='mr-1' />
														)}{" "}
														Search
													</Button >
												</div>
											</div>
										</>
									}}
								/> : "No Records found"
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sales