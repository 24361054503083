import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import { Button, Col, Row } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { CircularProgress } from "@material-ui/core";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useLiveQuery } from "dexie-react-hooks";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { db } from "./Dexie/db";
import Modal from "../../components/Modal";
import { errorToast, successToast } from "../../Element/toast";

const ScanOrigin = ({ next, PreviousButton, currentStep, setScanOrigin }) => {
	const [scan, setScanned] = useState(false);
	const [isScanned, setIsScanned] = useState(false);
	const [isLocationOptions, setIsLocationOptions] = useState([]);
	const [isLocationSame, setIsLocationSame] = useState(false);
	const [formikValues, setFormikValues] = useState({})

	const validationSchema = Yup.object().shape({
		ScanOrigin: Yup.object().required("Please scan or chosse origin."),
	});

	const origin = useLiveQuery(
		async () => {
			const Products = await db.Shipments.toArray() || [];
			return Products;
		}
	);

	const locations = useLiveQuery(
		async () => {
			const Locations = await db.Locations.toArray() || [];
			return Locations;
		}
	);

	const scanOriginAddress = async (result) => {
		let time = moment(new Date()).format()
		const isOriginPending = origin?.filter((origin) => origin.name === result);
		if (isOriginPending && !isOriginPending?.length) {
			try {
				await db.Shipments.add({ originName: result, originScanTime: time });
				successToast(`Origin successfully scanned`);

			} catch (error) {
				errorToast(`Failed to add: ${error}`);
			}
		}
	}

	const onSubmit = async (values) => {
		if (origin?.length > 0) {
			const filteredData = origin.filter(item => item.originId === values.ScanOrigin.id);
			if (filteredData?.length) {
				next()
			} else {
				setIsLocationSame(true)
				setFormikValues(values)
			}

			setScanOrigin(values?.ScanOrigin)
		} else {
			setScanOrigin(values?.ScanOrigin)
			let time = moment(new Date()).format()
			await db.Shipments.add({ originName: values?.ScanOrigin?.value, originId: values?.ScanOrigin.id, originScanTime: time, status: "onGoing" });
			next()
		}
	}

	const handleOkWithNewLocation = async () => {
		setIsLocationSame(!isLocationSame)
		next()
		db.Products.clear()
		db.Shipments.clear()
		let time = moment(new Date()).format()
		await db.Shipments.add({ originName: formikValues?.ScanOrigin?.value, originId: formikValues?.ScanOrigin.id, originScanTime: time, status: "onGoing" });
	}

	useEffect(() => {
		if (locations && locations?.length) {
			let a = locations.map((location) => {
				return {
					id: location?.id,
					label: location?.name,
					value: location?.name
				}
			})
			setIsLocationOptions(a)
		}
	}, [locations])

	return isLocationOptions?.length ? (
		<div>
			<Formik initialValues={{
				ScanOrigin: "",
				IsScanned: false,
			}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => {
					const {
						errors,
						touched,
						values,
						handleSubmit,
						handleBlur,
						setFieldValue,
					} = formik;
					return (
						<Row>
							<Col>
								<Row>
									<Col lg={6} md={12} className="mt-2 d-flex flex-column align-items-center justify-content-end ">
										<div>
											<Label for="IsScanned" style={{ fontSize: "50px" }}
												onClick={(e) => {
													setFieldValue("IsScanned", !isScanned);
													setIsScanned(!isScanned);
												}}>
												<i className="fa fa-qrcode" aria-hidden="true"></i>
											</Label>
										</div>
										{isScanned ? <div className="qr-code-div">
											{currentStep === 1 && <QrScanner
												onDecode={(result) => {
													if (!scan) {
														scanOriginAddress(result, setFieldValue)
														setScanned(!scan)
														setFieldValue("ScanOrigin", {
															value: result,
															label: result
														})
													} else {
														successToast("Already Scanned")
													}
												}}
											/>}
										</div> : ""}
									</Col>
									<Col lg={6} md={12} className="mt-2">
										<div className="input-group mt-lg-3 d-flex justify-content-center ">
											{!isScanned ?
												<div className="mt-lg-3 w-75">
													<Label for="ScanOrigin">
														Select origin
														<font color="red"> *</font>
													</Label>
													<AsyncPaginate
														className={`lazySelect`}
														name="ScanOrigin"
														value={values.ScanOrigin}
														options={isLocationOptions}
														placeholder={`Select origin`}
														onBlur={handleBlur}
														onChange={(e) => setFieldValue("ScanOrigin", e)}
													/>
													{touched.ScanOrigin && errors.ScanOrigin && (
														<font color="red">{errors.ScanOrigin}</font>
													)}
												</div>
												:
												<div className="mt-lg-3 w-75">
													<Label for="ScanOrigin">
														Select origin
														<font color="red"> *</font>
													</Label>
													<AsyncPaginate
														className={`lazySelect`}
														name="ScanOrigin"
														value={values.ScanOrigin}
														options={isLocationOptions}
														placeholder={`Select origin`}
														onBlur={handleBlur}
														onChange={(e) => setFieldValue("ScanOrigin", e)}
														isDisabled
													/>
													{touched.ScanOrigin && errors.ScanOrigin && (
														<font color="red">{errors.ScanOrigin}</font>
													)}
												</div>
											}
										</div>
									</Col>
								</Row >
							</Col>
							<div className="card-footer w-100 mt-3">
								<PreviousButton />
								<Button className="float-right" color="primary" onClick={() => {
									handleSubmit();
								}}>
									Next
								</Button>
							</div>
						</Row>
					)
				}}
			</Formik>
			{
				isLocationSame &&
				<Modal
					color="warning"
					title="Warning"
					message={
						<div className="p-3">
							<p color='orange'>Old scan process will be deleted, Are you sure?</p>
						</div>
					}
					handleOkClick={handleOkWithNewLocation}
					toggle={() => setIsLocationSame(!isLocationSame)}
				/>
			}
		</div>
	) : <div className="d-flex justify-content-center "><CircularProgress /></div>
};

export default ScanOrigin;
