import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';
import { useLazyGetAllRecordsQuery } from '../../../services/pcApi';
import { previousOption } from '../../../redux/reducers/Slice';
import { SHOPIFY_PRODUCT } from '../../../shared/tagFiles';
import ReactTable from '../../../shared/components/Table';
import { db } from '../ProductScan/Dexie/db';

const ShopifyOrders = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [getAllShopifyOrderRecords, {
        data: allShopifyOrderRecords,
        isLoading: isShopifyLoading,
        isShopifyApiSuccess }] = useLazyGetAllRecordsQuery();
    const history = useHistory()
    const dispatch = useDispatch();

    const columns = [
        {
            Header: "Order Number",
            accessor: "orderNumber",
            Cell: (({ value }) => {
                return value ? `#${value}` : "-"
            })
        },
        {
            Header: "Processed At",
            accessor: "processedAt",
            type: "date",
            filter: false,
            Cell: (({ value }) => {
                return value ? moment(value).format('MM-DD-YYYY h: mm a') : "-"
            })
        },
        {
            Header: "Status",
            accessor: "fulfillmentStatus",
            filter: false,
            Cell: (({ value }) => {
                return (
                    <span className={`${value === null && "text-danger"}`}>
                        {value === null ? "Unshipped" : value === "fulfilled" ? "Shipped" : value === "partial" ? "Partial" : "Any"}</span>
                )
            })
        }
    ]

    const orders = useLiveQuery(
        async () => {
            try {
                setIsLoading(true);
                const Orders = await db.ShopifyOrders?.toArray();
                return Orders;
            } finally {
                setIsLoading(false);
            }
        }
    );

    const handleShowDetails = (order) => {
        dispatch(previousOption({
            label: "Shopify",
            value: "shopify"
        }))

        history.push(`/shopify/order-details`, {
            orderData: order
        })
    }
    useEffect(() => {
        if (orders?.length === 0 && !isShopifyApiSuccess) {
            getAllShopifyOrderRecords({
                entity: `Shopify/GetAllFilteredOrderFromShopify`,
                allRecords: true,
                tag: SHOPIFY_PRODUCT,
            });
        }
    }, [orders])

    useEffect(() => {
        if (allShopifyOrderRecords?.value?.length && !isShopifyLoading) {
            let time = new Date()
            db.ShopifyOrders.clear();
            allShopifyOrderRecords?.value?.forEach((item, index) => {
                db.ShopifyOrders.add({
                    Id: index + 1,
                    fulfillmentStatus: item.FulfillmentStatus,
                    orderNumber: item.OrderNumber,
                    processedAt: item.ProcessedAt,
                    shopifyOrderId: item.Id,
                    LastSynced: time,
                })
            })
        }
    }, [allShopifyOrderRecords, isShopifyLoading])


    return (
        <>
            <div className="card__title d-flex justify-content-between align-items-center">
                <div className='d-flex align-items-baseline'>
                    <h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i>Shopify Orders</h5>
                    <span style={{ cursor: "pointer" }} onClick={() => db.ShopifyOrders.clear()}><i className="fa fa-refresh ml-2" aria-hidden="true"></i></span>
                </div>
            </div>
            <ReactTable columns={columns} data={orders?.length ? orders : []} isLoading={(isShopifyLoading || isLoading)} hasHandleShow handleShowDetails={handleShowDetails} disabledGlobalSearch />
        </>
    )
}

export default ShopifyOrders