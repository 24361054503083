import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { Form, Formik } from 'formik';
import bgimg from './../../images/BackgroundImage.png';
import Details from './ProductScan/Details';
import ScanDestination from './ProductScan/ScanDestination';
import ProductScan from './ProductScan/ProductScan';
import ScanOrigin from './ProductScan/ScanOrigin';

const AddProduct = () => {
	const [currentStep, setCurrentStep] = useState(1)
	const [scanOrigin, setScanOrigin] = useState()
	const [scanProducts, setScanProducts] = useState()
	const [scanDestiny, setScanDestiny] = useState()

	const next = () => {
		let current = currentStep;
		current = current + 1;
		setCurrentStep(current);
	}

	const prev = () => {
		let current = currentStep;
		current = current <= 1 ? 1 : current - 1;
		setCurrentStep(current);
	}

	const PreviousButton = () => {
		let current = currentStep;

		if (current !== 1) {
			return (
				<Button className="float-left" color="secondary" onClick={prev}>
					Previous
				</Button>
			);
		}
		return null;
	}

	return (
		<>
			<div className="full-section">
				<div className="dlab-bnr-inr overlay-primary-dark contact-page" style={{ backgroundImage: "url(" + bgimg + ")" }}>
					<div className="container-fluid">
						<div className="row justify-content-center ">
							<div className="col-lg-10 col-xs-10">
								<Formik
									enableReinitialize={true}
								>
									{(formik) => {
										const { errors, handleBlur, handleChange } = formik;
										return (
											<Form className="inquiry-form dzForm d-flex justify-content-center align-content-center consultation-form" action="script/contact.php">
												<Card style={{ width: "100%" }}>
													<CardBody className="py-0 px-3">
														<Tabs
															defaultActiveKey={currentStep}
															activeKey={currentStep}
															id="fill-tab-example"
															// className="mb-3"
															fill
														>
															<Tab eventKey={1} title="Scan the Origin">
																<ScanOrigin
																	next={next}
																	PreviousButton={PreviousButton}
																	currentStep={currentStep}
																	setScanOrigin={setScanOrigin}
																	scanOrigin={scanOrigin}
																/>
															</Tab>
															<Tab eventKey={2} title="Start Scanning">
																<ProductScan
																	next={next}
																	PreviousButton={PreviousButton}
																	currentStep={currentStep}
																	scanOrigin={scanOrigin}
																	setScanProducts={setScanProducts}
																	scanProducts={scanProducts}
																/>
															</Tab>
															<Tab eventKey={3} title="Scan the Destination">
																<ScanDestination
																	next={next}
																	PreviousButton={PreviousButton}
																	currentStep={currentStep}
																	setScanDestiny={setScanDestiny}
																	scanDestiny={scanDestiny}
																/>
															</Tab>
															<Tab eventKey={4} title="Process Transfer Batch">
																{currentStep === 4 && <Details
																	PreviousButton={PreviousButton}
																	currentStep={currentStep}
																/>}
															</Tab>
														</Tabs>
													</CardBody>
												</Card>
											</Form>
										)
									}
									}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default AddProduct;