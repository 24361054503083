import React, { useEffect, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks';
import { useLocation } from 'react-router-dom';
import { db } from './ProductScan/Dexie/db';
import { Col, Row } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';

const SkuDetails = () => {
    const [product, setProduct] = useState()
    const location = useLocation();
    const variantSku = location.state.state.sku
    const products = useLiveQuery(
        async () => {
            const AllShopifyProducts = await db.AllShopifyProducts.toArray();
            return AllShopifyProducts;
        }
    );

    useEffect(() => {
        if (products?.length) {
            products.forEach((pro) => {
                if (pro.variantSku == variantSku) {
                    setProduct(pro)
                }
            })
        }
    }, [products])


    return product && Object.keys(product)?.length ? (
        <Row className="product-details-container">
            <Col md={6} className="d-flex justify-content-center product-image">
                <img src={product?.productImage ? product?.productImage : ""} alt={product?.productTitle} />
            </Col>
            <Col md={6} className="product-info">
                <h2 className="product-title">{product?.productTitle}</h2>
                <p className="product-price">Price: ${product?.variantPrice}</p>
                <p className='product-other-details'><span>Inventory Trackable:</span> {product?.inventoryTrackable ? "Yes" : "No"}</p>
                <p className='product-other-details'><span>Product Status:</span>
                    <span className={`product-status ${product.productStatus === "active" ? "text-success" : product.productStatus === "draft" ? "text-primary" : "text-secondary"}`}>{product?.productStatus}</span>
                </p>
                <p className='product-other-details'><span>Product Vendor:</span> {product?.productVendor}</p>
                <p className='product-other-details'><span>Variant Sku:</span> {product?.variantSku}</p>
                <p className='product-other-details'><span>Variant Title:</span> {product?.variantTitle}</p>
                <p className='product-other-details'><span>Varinat Id:</span> {product?.varinatId}</p>
            </Col>
        </Row>
    ) : <div className="d-flex justify-content-center "><CircularProgress /></div>;
}

export default SkuDetails
