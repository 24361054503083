import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth0 } from "@auth0/auth0-react"
import Cookies from "universal-cookie"
import { CircularProgress } from "@material-ui/core";
import { useAddMutation } from "../../services/pcApi";
import { auth } from "../../redux/actions/authActions";
import { USER_TAG } from "../../shared/tagFiles";
import { DRIVER } from "../../services/userRoles";
import Modal from "../components/Modal";
import RelationForm from "./RelationForm";
import AddModal from "../../shared/components/AddModal";
import { loginUser } from "../../redux/reducers/Slice";
import { db } from "../Pages/ProductScan/Dexie/db";

const HeaderMenu = () => {
	const isProductLoading = useSelector(state => state.data.isProductLoader)
	const [isActive, setIsActive] = useState("/");
	const [role, setRole] = useState("");
	const [isRegisterModelOpen, setIsRegisterModelOpen] = useState(false);
	const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
	const {
		loginWithRedirect,
		isAuthenticated,
		logout,
		getAccessTokenSilently,
		user,
	} = useAuth0()

	const [addUser] = useAddMutation();
	const history = useHistory();
	const dispatch = useDispatch();

	let visibleForEmployee = isAuthenticated && role?.userRelationRequestDTO?.relation?.toLowerCase() === "employee" && role?.userRelationRequestDTO?.status?.toLowerCase() === "approve"
	let visibleAfterLoginWithData = loginUser?.userRelationRequestDTO && !loginUser?.userRelationRequestDTO?.status === 'Approve'

	useEffect(() => {
		setIsActive(history?.location?.pathname);
	}, [history?.location?.pathname])

	useEffect(() => {
		if (isAuthenticated) {
			user.role = [DRIVER];
			Object.entries(user).forEach(([key, value]) => {
				if (key.includes("roles") && value.length > 0) {
					user.role = value;
				}
			});
			const userDetail = {
				Name: user.name,
				Description: user.Description ? user.Description : "",
				Email: user.email,
				SsoIdentifier: user.sub,
				Role: "Driver"
			};
			var accessToken = "";
			const getToken = async () => {
				accessToken = await getAccessTokenSilently({
					audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				});
				let cookies = new Cookies()
				cookies.set("accessToken", accessToken)
			};
			getToken();
			let tokenInterval = setInterval(() => {
				if (accessToken.length > 0) {
					addUser({
						entity: "Auth/CreateUser",
						data: userDetail,
						tag: USER_TAG,
					}).then((response) => {
						if (!response.error) {
							setRole(response.data)
							dispatch(loginUser(response.data));
							if (!response.data?.relationDTO && !response.data?.userRelationRequestDTO) {
								setIsRegisterModelOpen(true)
							}
							if (!response.data?.relationDTO && (response.data?.userRelationRequestDTO?.status.toLowerCase() === "pending" || response.data?.userRelationRequestDTO?.status.toLowerCase() === "reject")) setIsAlreadyRegistered(true)

							dispatch(
								auth({
									...user,
									isAuthenticate: true,
									userId: response.data?.id,
								}))
						}
					});
					clearInterval(tokenInterval);
				}
			}, 2000);
		}
		// eslint-disable-next-line
	}, [isAuthenticated]);

	return (
		<>
			<ul className="nav navbar-nav">
				<li className={isActive === "/" ? "active" : ""} onClick={() => setIsActive(history?.location?.pathname)}>
					<Link to={'/'}><span className="ti-home"></span> Home</Link>
				</li>
				<li className={isActive === "/privacy-policy" ? "active" : ""} onClick={() => setIsActive(history?.location?.pathname)}>
					<Link to={"/privacy-policy"}>Privacy Policy</Link>
				</li>
				<li className={isActive === "/contact" ? "active" : ""} onClick={() => setIsActive(history?.location?.pathname)}>
					<Link to={"/contact"}>Contact Us</Link>
				</li>
				{visibleForEmployee && !isProductLoading ?
					<li className={(isActive === "/products" || isActive === "/add-product" || isActive === "/order" || isActive === "/receiving-list") ? "active" : ""}>
						<Link to={"#"}>Product <i className="fa fa-angle-down fs-4"></i></Link>
						<ul className="sub-menu">
							<li onClick={() => setIsActive(history?.location?.pathname)}><Link to={"/products"}>All Product</Link></li>
							<li onClick={() => setIsActive(history?.location?.pathname)}><Link to={"/order"}>All Order</Link></li>
							<li onClick={() => setIsActive(history?.location?.pathname)}><Link to={"/add-product"}> Scan product</Link></li>
							<li onClick={() => setIsActive(history?.location?.pathname)}><Link to={"/receiving-list"}>Receiving</Link></li>
						</ul>
					</li> : !visibleAfterLoginWithData && (isAuthenticated && visibleForEmployee) ? <div className={`d-flex justify-content-center align-items-center`}><CircularProgress size={20} style={{ color: "black" }} /></div> : ""}
				{isAuthenticated && role?.userRelationRequestDTO?.status?.toLowerCase() === "approve" &&
					<li className={isActive === "/commission-report" ? "active" : ""} onClick={() => setIsActive(history?.location?.pathname)}>
						<Link to={"/commission-report"}>Commission Report</Link>
					</li>}
				{isAuthenticated ?
					<li className={(isActive === "/profile") ? "active" : ""}>
						<Link to={"#"}><i className="fa fa-user" style={{ fontSize: "18px" }}></i> <i className="fa fa-angle-down fs-4"></i></Link>
						<ul className="sub-menu">
							<li onClick={() => setIsActive(history?.location?.pathname)}><Link to={'/profile'}>Profile</Link></li>
							<li>
								<Link to={'#'} onClick={() => {
									logout({ returnTo: process.env.REACT_APP_LOGIN_REDIRECT });
									let cookies = new Cookies()
									cookies.remove("accessToken")
									localStorage.removeItem("accessToken")
									db.AllShopifyProducts.clear()
									db.Transfers.clear()
									db.Locations.clear()
									db.Sales.clear()
									db.SalesByQuarterly.clear()
									db.ShopifyOrders.clear()
									db.BackerKitOrders.clear()
								}}>Logout</Link>
							</li>
						</ul>
					</li>
					: <li><Link to={'#'} onClick={loginWithRedirect} className="hitchtowing-page">Login</Link></li>
				}
			</ul>
			{isRegisterModelOpen && (
				<AddModal
					toggle={() => setIsRegisterModelOpen(!isRegisterModelOpen)}
					color="success"
					title={"Role"}
					header
					btn="Success"
					message={
						<RelationForm
							setIsAddModalOpen={setIsRegisterModelOpen}
							setIsAlreadyRegistered={setIsAlreadyRegistered}
							newRequest={role?.userRelationRequestDTO?.status.toLowerCase() === "reject"}
						/>
					}
				/>
			)
			}
			{
				isAlreadyRegistered &&
				<Modal
					color="primary"
					title="Note"
					message={
						<div className="p-3">
							{role?.userRelationRequestDTO?.status.toLowerCase() === "pending" ?
								<p>You have already registered as an <b>{role?.userRelationRequestDTO?.relation}</b>, but your approval is pending from the admin's side.</p>
								:
								<p>Your role request has been rejected due to <b>{role?.userRelationRequestDTO?.rejectionReason}</b>. Please contact the administrator for more details.</p>
							}
						</div>
					}
					buttonOkName={`${role?.userRelationRequestDTO?.status.toLowerCase() === "reject" ? "Submit New Request" : "Ok"}`}
					handleOkClick={() => role?.userRelationRequestDTO?.status.toLowerCase() === "reject" ? setIsRegisterModelOpen(!isRegisterModelOpen) : setIsAlreadyRegistered(!isAlreadyRegistered)}
					toggle={() => setIsAlreadyRegistered(!isAlreadyRegistered)}
				/>
			}
		</>
	)
}
export default HeaderMenu;