import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import { Button, Col, Row } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useLiveQuery } from "dexie-react-hooks";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { errorToast, successToast } from "../../Element/toast";
import { db } from "./Dexie/db";
import { CircularProgress } from "@material-ui/core";

const ScanDestination = ({ next, PreviousButton, currentStep }) => {
	const [scan, setScanned] = useState(false);
	const [isScanned, setIsScanned] = useState(false);
	const [isLocationOptions, setIsLocationOptions] = useState([]);

	const validationSchema = Yup.object().shape({
		ScanDestiny: Yup.object().required("Please scan or chosse destiny."),
	});

	const origin = useLiveQuery(
		async () => {
			const Shipments = await db.Shipments.orderBy('id').reverse().toArray() || [];
			return Shipments;
		}
	);

	const locations = useLiveQuery(
		async () => {
			const Locations = await db.Locations.toArray() || [];
			return Locations;
		}
	);

	const scanDestinyAddress = async (result) => {
		let time = moment(new Date()).format()
		const isOriginPending = origin?.filter((origin) => origin.name === result);
		if (isOriginPending && !isOriginPending?.length) {
			try {
				await db.Shipments.update(origin?.[0]?.id, { destinationName: result, destinationScanTime: time });
				successToast(`Destiny successfully scanned`);

			} catch (error) {
				errorToast(`Failed to add: ${error}`);
			}
		}
	}

	const onSubmit = async (values) => {
		let time = moment(new Date()).format()
		await db.Shipments.update(origin?.[0]?.id, { destinationName: values.ScanDestiny.value, destinationScanTime: time, destinationId: values.ScanDestiny.id });
		next()
	}

	useEffect(() => {
		if (locations && locations?.length) {
			let a = locations.map((location) => {
				return {
					id: location?.id,
					label: location?.name,
					value: location?.name
				}
			})
			setIsLocationOptions(a)
		}
	}, [locations])

	return isLocationOptions?.length ? (
		<div>
			<Formik initialValues={{
				ScanDestiny: "",
				IsScan: false,
			}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => {
					const {
						errors,
						touched,
						values,
						handleSubmit,
						handleBlur,
						setFieldValue,
					} = formik;
					return (
						<Row>
							<Col>
								<Row>
									<Col lg={6} md={12} className="mt-2 d-flex flex-column align-items-center justify-content-end ">
										<div>
											<Label for="IsScan" style={{ fontSize: "50px" }}
												onClick={(e) => {
													setFieldValue("IsScanned", !isScanned);
													setIsScanned(!isScanned);
												}}>
												<i className="fa fa-qrcode" aria-hidden="true"></i>
											</Label>
										</div>
										{isScanned ? <div className="qr-code-div">
											{currentStep === 3 && <QrScanner
												onDecode={(result) => {
													if (!scan) {
														scanDestinyAddress(result, setFieldValue);
														setScanned(!scan)
														setFieldValue("ScanDestiny", {
															value: result,
															label: result
														})
													} else {
														successToast("Already Scanned")
													}
												}}
											/>}
										</div> : ""}
									</Col>

									<Col lg={6} md={12} className="mt-2">
										<div className="input-group mt-lg-3 d-flex justify-content-center ">
											{!isScanned ?
												<div className="mt-lg-3 w-75">
													<Label for="ScanDestiny">
														Select destination
														<font color="red"> *</font>
													</Label>
													<AsyncPaginate
														className={`lazySelect`}
														name="ScanDestiny"
														value={values.ScanDestiny}
														options={isLocationOptions}
														placeholder={`Select destination`}
														onBlur={handleBlur}
														onChange={(e) => setFieldValue("ScanDestiny", e)}
													/>
													{touched.ScanDestiny && errors.ScanDestiny && (
														<font color="red">{errors.ScanDestiny}</font>
													)}
												</div>
												:
												<div className="mt-lg-3 w-75">
													<Label for="ScanDestiny">
														Select destination
														<font color="red"> *</font>
													</Label>
													<AsyncPaginate
														className={`lazySelect`}
														name="ScanDestiny"
														value={values.ScanDestiny}
														options={isLocationOptions}
														placeholder={`Select destination`}
														onBlur={handleBlur}
														onChange={(e) => setFieldValue("ScanDestiny", e)}
														isDisabled
													/>
													{touched.ScanDestiny && errors.ScanDestiny && (
														<font color="red">{errors.ScanDestiny}</font>
													)}
												</div>
											}
										</div>
									</Col>
								</Row >
							</Col>
							<div className="card-footer w-100 mt-3">
								<PreviousButton />
								<Button className="float-right" color="primary" onClick={() => {
									handleSubmit();
								}}>
									Next
								</Button>
							</div>
						</Row>
					)
				}}
			</Formik>
		</div>
	) : <div className="d-flex justify-content-center "><CircularProgress /></div>
};
export default ScanDestination;
