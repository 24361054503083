import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core';
import { Table } from 'react-bootstrap';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from './ProductScan/Dexie/db';
import { useHistory } from "react-router-dom";

const AllSku = () => {
	const [filteredData, setFilteredData] = useState([]);
	const history = useHistory()
	const products = useLiveQuery(
		async () => {
			const AllShopifyProducts = await db.AllShopifyProducts.toArray();
			return AllShopifyProducts;
		}
	);

	const handleShowDetails = (sku) => {
		history.push(`/sku/${sku}`, {
			state: {
				sku: sku,
			},
		})
	}

	const onSearch = (data) => {
		const filteredResults = products?.filter((str) =>
			str?.variantSku.toLowerCase().includes(data.toLowerCase()) ||
			str?.variantTitle.toLowerCase().includes(data.toLowerCase()) ||
			str?.variantPrice.toLowerCase().includes(data.toLowerCase()) ||
			str?.productVendor.toLowerCase().includes(data.toLowerCase()) ||
			str?.productTitle.toLowerCase().includes(data.toLowerCase()) ||
			str?.productStatus.toLowerCase().includes(data.toLowerCase())
		);
		setFilteredData(filteredResults);
	}

	return products?.length ? (
		<>
			<div className='px-3'>
				<h3 className='pt-3'>Variants</h3>
				<input
					type="text"
					placeholder="Search by title"
					onChange={(e) => onSearch(e.target.value)}
				/>

				<Table responsive>
					<thead>
						<tr>
							<th>Id</th>
							<th>SKU</th>
							<th>Quantity</th>
							<th>Variant Title</th>
							<th>Variant Price</th>
							<th>Vendor</th>
							<th>Product Title</th>
							<th>Product Status</th>
							<th>Product Image</th>
						</tr>
					</thead>
					<tbody>
						{
							filteredData?.length ?
								filteredData?.map((product, i) => {
									i += 1
									return (
										<tr key={i} onClick={() => handleShowDetails(product.variantSku)}>
											<td>{i}</td>
											<td>{product?.variantSku ? product?.variantSku : "No SKU"}</td>
											<td>{product?.inventoryQuantity ? product?.inventoryQuantity : "0"}</td>
											<td>{product?.variantTitle ? product?.variantTitle : "-"}</td>
											<td>{product?.variantPrice ? `$${product?.variantPrice}` : "-"}</td>
											<td>{product?.productVendor ? product?.productVendor : "-"}</td>
											<td>{product?.productTitle ? product?.productTitle : "-"}</td>
											<td><span className={`product-status ${product.productStatus === "active" ? "bg-success" : product.productStatus === "draft" ? "bg-primary" : "bg-secondary"}`}>{product?.productStatus}</span></td>
											<td>{product?.productImage && <img src={product?.productImage} alt='no image' width={50} />}</td>
										</tr>)
								}) :
								products?.map((product, i) => {
									i += 1
									return (
										<tr key={i} onClick={() => handleShowDetails(product.variantSku)}>
											<td>{i}</td>
											<td>{product?.variantSku ? product?.variantSku : "No SKU"}</td>
											<td>{product?.inventoryQuantity ? product?.inventoryQuantity : "0"}</td>
											<td>{product?.variantTitle ? product?.variantTitle : "-"}</td>
											<td>{product?.variantPrice ? `$${product?.variantPrice}` : "-"}</td>
											<td>{product?.productVendor ? product?.productVendor : "-"}</td>
											<td>{product?.productTitle ? product?.productTitle : "-"}</td>
											<td><span className={`product-status ${product.productStatus === "active" ? "bg-success" : product.productStatus === "draft" ? "bg-primary" : "bg-secondary"}`}>{product?.productStatus}</span></td>
											<td>{product?.productImage && <img src={product?.productImage} alt='no image' width={50} />}</td>
										</tr>)
								})
						}
					</tbody>
				</Table>
			</div >
		</>
	) : <div className="d-flex justify-content-center "><CircularProgress /></div>
}

export default AllSku