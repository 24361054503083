import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import AddProduct from './Pages/AddProduct';
import ScrollToTop from './Element/ScrollToTop';
import Footer from './Layout/footer';
import Header from './Layout/header';
import Error404 from './Pages/Error/Error404';
import SkuDetails from './Pages/SkuDetails';
import AllProducts from './Pages/AllProducts';
import AllSku from './Pages/AllSku';
import Receiving from './Pages/Receiving';
import ReceivingDetails from './Pages/ProductReceiving/ReceivingDetails';
import AllLocations from './Pages/AllLocations';
import Sales from './Pages/Sales';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Contact from './Pages/Contact';
import SalesDetails from './Pages/SalesDetails';
import SalesbyQuarterly from './Pages/SalesbyQuarterly';
import SalesBreakdown from './Pages/SalesBreakdown';
import Profile from './Pages/Profile';
import AllOrders from './Pages/AllOrders';
import ShopifyOrderDetails from './Pages/Orders/ShopifyOrderDetails';
import BackerKitOrderDetails from './Pages/Orders/BackerKitOrderDetails';

const Markup = () => {
    return (
        <BrowserRouter basename="/">
            <Header />
            <div className="page-wraper">
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                    <Route path='/add-product' component={AddProduct} />
                    <Route path='/products' component={AllProducts} />
                    <Route path='/locations' component={AllLocations} />
                    <Route path='/sales' component={Sales} />
                    <Route path='/commission-report' component={SalesbyQuarterly} />
                    <Route path='/sales-breakdown' component={SalesBreakdown} />
                    <Route path='/sales-details' component={SalesDetails} />
                    <Route path='/all-sku' component={AllSku} />
                    <Route path='/order' component={AllOrders} />
                    <Route path='/shopify/order-details' component={ShopifyOrderDetails} />
                    <Route path='/backerkit/order-details' component={BackerKitOrderDetails} />
                    <Route path='/backerkit/order-group-details' render={(props) => <BackerKitOrderDetails tag={"grp"} {...props} />} />
                    <Route path='/sku/:variantSku' component={SkuDetails} />
                    <Route path='/receiving-list' component={Receiving} />
                    <Route path='/transfer/:transferId' component={ReceivingDetails} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="*" component={Error404} />
                </Switch>
            </div>
            <Footer />
            <ScrollToTop />
        </BrowserRouter>
    )
}

export default Markup;