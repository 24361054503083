import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import { CircularProgress } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import Slider8 from "../../../images/BackgroundImage.png";
import TotalBox from "./TotalBox";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}
const Content = ({ isProductLoading }) => {
  const loginUser = useSelector(state => state?.data?.user)
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  var settings = {
    arrows: true,
    dots: false,
    slidesToShow: 1,
    fade: true,
    infinite: true,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <Slider
      className="owl-slider owl-carousel owl-theme owl-none "
      {...settings}
    >
      <div className="item slide-item">
        <div className="slide-item-img">
          <img src={Slider8} className="w-100" alt="" style={{ opacity: "0.2" }} />
        </div>
        <div className="slide-content ">
          <Row className="slide-content-box flex-lg-row flex-md-column-reverse">
            <Col xl={6} lg={8} md={12}>
              <h3 className="font-weight-600 text-light">Organized Play</h3>
              <p className="text-light banner-text">
                Our GM League teams run hundreds of hours of events at all of the major shows. We believe that played games are games that sell.
                By stimulating your organized play network through hosting many events per tradeshow,
                we believe that your product lines will increase in popularity and more retailers will pick up your product lines.
                With highly ranked professional GMs who have background in programs like Adventure League and Adventure Paths, we can deliver premium entertainment to your fans at all major shows.
              </p>

              {isAuthenticated && loginUser?.userRelationRequestDTO && loginUser?.userRelationRequestDTO?.relation.toLowerCase() === 'employee' && loginUser?.userRelationRequestDTO?.status === 'Approve' &&
                <Link
                  to={"/add-product"}
                  className={`site-button button-md radius-xl mr-2`}>
                  Scan product
                </Link>}

              {!isAuthenticated && <Link
                to={"#"}
                className={`site-button button-md radius-xl mr-2`}
                onClick={loginWithRedirect}>
                Get Started
              </Link>}

              {isAuthenticated && loginUser?.userRelationRequestDTO && loginUser?.userRelationRequestDTO?.relation.toLowerCase() === 'client' && loginUser?.userRelationRequestDTO?.status === 'Approve' && loginUser?.userRelationRequestDTO && loginUser?.userRelationRequestDTO?.relation.toLowerCase() === 'employee' && loginUser?.userRelationRequestDTO?.status === 'Approve' && !isProductLoading &&
                <Link
                  to={"/commission-report"}
                  className={`site-button button-md radius-xl mr-2`}>
                  Commission Report
                </Link>}

            </Col>
            {isAuthenticated ? <Col xl={6} lg={4} md={12} className={`d-flex justify-content-center mb-md-2 mt-sm-2 ${loginUser && Object.entries(loginUser)?.length ? '' : "align-items-center"} `}>
              {loginUser && Object.entries(loginUser)?.length ? <TotalBox loginUser={loginUser} isProdLoading={isProductLoading} /> : <div className={`d-flex justify-content-center align-items-center`}><CircularProgress size={20} style={{ color: "white" }} /></div>}
            </Col> : ""}
          </Row>
        </div>
      </div>
    </Slider >
  );
};

export default Content;
