import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment/moment';
import { useAddMutation, useLazyGetAllRecordsQuery } from '../../services/pcApi';
import { SHOPIFY_QUANTITY_LOCATIONS } from '../../shared/tagFiles';
import ReactTable from '../../shared/components/Table';
import { successToast } from '../Element/toast';
import { db } from './ProductScan/Dexie/db';

const SalesbyQuarterly = () => {
	const [tableHeader, setTableHeader] = useState([]);
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [filteredVendor, setFilteredVendor] = useState([]);
	const [allVendors, setAllVendors] = useState([])
	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const loginUser = useSelector(state => state?.data?.user)
	const [getAllSalesRecords, { isLoading: isSalesLoading }] = useLazyGetAllRecordsQuery();
	const [getAllVendorRecords] = useLazyGetAllRecordsQuery();
	const [createTransfer, { isLoading: iscreateTransferLoading }] = useAddMutation();

	const salesByQuarterly = useLiveQuery(
		async () => {
			const SalesByQuarterly = await db.SalesByQuarterly.toArray();
			return SalesByQuarterly;
		});

	const vendorNames = (() => {
		let vendors = [];
		const vendorObject = loginUser?.userRelationRequestDTO?.vendorObject;
		if (loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "client" && vendorObject) {
			vendors = JSON.parse(vendorObject).map(vendor => ({
				label: vendor.Name,
				value: vendor.Name
			}));
		} else {
			vendors = allVendors?.length && allVendors?.map(vendor => ({
				label: vendor.Name,
				value: vendor.Name
			}));
		}

		if (vendors?.length) {
			return vendors?.sort((a, b) => a.label.localeCompare(b.label));
		} else {
			return []
		}
	})();

	const base64ToBlob = (base64String, contentType) => {
		const sliceSize = 1024;
		const byteCharacters = atob(base64String);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};

	const downloadPdf = () => {
		const payload = filteredVendor
		createTransfer({
			entity: `Shopify/ExportQuarterSalesReportByVendorAsPDF`,
			data: payload,
		}).then((response) => {
			if (!response.error) {
				let dateTime = moment();
				const blob = base64ToBlob(response?.data, 'application/pdf');
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = `${filteredVendor[0].ProductVendor + "_" + dateTime.format('DDMMYYYY')}`;
				link.click();
				window.URL.revokeObjectURL(url);
				successToast("PDF has been generated.")
			}
		});
	};

	function arraysEqual(arr1, arr2) {
		if (arr1?.length !== arr2?.length) return false;
		for (let i = 0; i < arr1?.length; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	}

	const handleShowDetails = (data) => {
		history.push({
			pathname: "/sales-breakdown",
			state: {
				data: data,
				loginUser: loginUser,
				allVendors: allVendors
			},
		});
	};

	useEffect(() => {
		if (loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "employee") {
			getAllVendorRecords({
				entity: `ShopifyReference/ShopifyVendors`,
				allRecords: true,
				tag: SHOPIFY_QUANTITY_LOCATIONS,
			}).then((response) => {
				setAllVendors(response?.data?.value)
			})
		}
	}, [loginUser])

	useEffect(() => {
		const filteredData = salesByQuarterly?.filter(item => item?.ProductVendor === (selectedVendor?.value || vendorNames?.[0]?.value));
		if (!arraysEqual(filteredData, filteredVendor)) {
			setFilteredVendor(filteredData);
		}
	}, [salesByQuarterly, selectedVendor, vendorNames]);

	useEffect(() => {
		if (salesByQuarterly?.length === 0) {
			const vendorNames = (loginUser?.userRelationRequestDTO?.relation?.toLowerCase() === "client"
				? JSON.parse(loginUser?.userRelationRequestDTO?.vendorObject || '[]')
				: []
			)?.map(vendor => vendor?.Name);

			const filterQuery = `${vendorNames?.map(item => `ProductVendor eq '${encodeURIComponent(item.replace(`'`, `''`))}'`).join(' or ')}`;
			getAllSalesRecords({
				entity: `Shopify/GetAllSalesByProductVariantQuarterlyFromShopify`,
				count: true,
				allRecords: true,
				filter: filterQuery,
				tag: SHOPIFY_QUANTITY_LOCATIONS,
			}).then((response) => {
				if (!response.error) {
					db.SalesByQuarterly.clear();
					response.data?.value?.forEach(item => {
						db.SalesByQuarterly.add({
							Id: item?.Id,
							ProductId: item?.ProductId,
							ProductTitle: item?.ProductTitle,
							ProductVendor: item?.ProductVendor,
							VariantId: item?.VariantId,
							VariantTitle: item?.VariantTitle,
							VariantSKU: item?.VariantSKU,
							NetQuantity: item?.NetQuantity,
							Price: item?.Price,
							GrossSale: item?.GrossSale,
							TotalTax: item?.TotalTax,
							TotalSales: item?.TotalSales,
							QuarterQuantityDTOs: item?.QuarterQuantityDTOs,
							TotalDiscounts: item?.TotalDiscounts,
							TotalReturns: item?.TotalReturns,
							TotalCommission: item?.TotalCommission,
							NetSales: item?.NetSales,
							RefundProductVariantDTOs: item?.RefundProductVariantDTOs,
							TotalSupplementaryCommission: item?.TotalSupplementaryCommission,
							TotalSupplementaryNetSale: item?.TotalSupplementaryNetSale,
							TotalSupplementaryQuantity: item?.TotalSupplementaryQuantity
						});
					})
				}
				setLoading(false)
			})
		}
	}, [salesByQuarterly]);

	useEffect(() => {
		const getLastCompletedQuarter = () => {
			const today = moment().format();
			let lastCompletedQuarterStart = moment(today).startOf('quarter');
			let lastCompletedQuarterEnd = moment(lastCompletedQuarterStart).add(-3, 'months');

			while (!lastCompletedQuarterEnd.isBefore(lastCompletedQuarterStart, 'day')) {
				lastCompletedQuarterStart = moment(lastCompletedQuarterStart).subtract(3, 'months');
				lastCompletedQuarterEnd = moment(lastCompletedQuarterStart).add(-3, 'months');
			}

			return {
				start: lastCompletedQuarterStart.format('MM-DD-YYYY'),
				end: lastCompletedQuarterEnd.format('MM-DD-YYYY')
			};
		};
		const lastCompletedQuarter = getLastCompletedQuarter();

		const today = moment(lastCompletedQuarter.end).format();
		let temp = [];
		for (let i = 0; i < 4; i++) {
			const quarter = moment(today).subtract(i, 'quarters');
			temp.push(quarter.format('Q') + ' ' + quarter.format('YYYY'));
		}
		setTableHeader(temp);
	}, []);

	return useMemo(() => {
		const columns = [
			{
				Header: 'Product Line',
				accessor: 'ProductTitle',
				with: "VariantSKU",
				Cell: ({ value }) => {
					return value ?? "-";
				}
			},
			...tableHeader.map((quarter, index) => ({
				Header: `Q${quarter}`,
				accessor: `QuarterQuantityDTOs[${index}].NetSales`,
				quantitySchema: `QuarterQuantityDTOs`,
				quantityAccessorInd: index,
				quantityValue: "NetSales",
				filter: false,
				Cell: (val => {
					const value = val.cell.row.original[`QuarterQuantityDTOs`][index]
					const data = (value[`Commission`] + value[`SupplementaryCommission`])
					return <span style={data <= 0 ? { color: "red", fontSize: "medium" } : { fontSize: "medium" }}>
						{`$${data.toFixed(2)}`}
					</span>
				})
			})),
		];

		return (
			<>
				<div className='py-3'>
					<div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
						<div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
							<div className="d-flex justify-content-between align-items-center mb-2 flex-wrap"><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i>Commission Report</h5>
								<div>
									<Button className="mr-2" onClick={() => { downloadPdf() }} disabled={(iscreateTransferLoading || !filteredVendor?.length)}>
										{iscreateTransferLoading && <CircularProgress color="inherit" size={18} className='mr-1' />}
										Export to PDF</Button>
									<Button onClick={() => { db.SalesByQuarterly.clear(); setLoading(true) }} disabled={(isSalesLoading || loading)}>
										{(isSalesLoading || loading) && <CircularProgress color="inherit" size={18} className='mr-1' />}
										Refresh
									</Button>
								</div>
							</div>
							<div>
								<ReactTable columns={columns} data={filteredVendor || []} isLoading={(isSalesLoading || loading)} hasHandleShow disabledGlobalSearch handleShowDetails={handleShowDetails} ExtraStuff={() => (
									<>
										<label htmlFor="vendorSelect" className='mb-0 mr-1'>Select a vendor:</label>
										<Select
											id="vendorSelect"
											className='w-50'
											value={(selectedVendor || vendorNames?.[0])}
											onChange={(e) => setSelectedVendor(e)}
											options={vendorNames && vendorNames}
											placeholder="Select..."
											isLoading={!(vendorNames && vendorNames?.length)}
											loadingMessage={() => "Loading..."}
										/>
									</>
								)}
									footer
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}, [loading, tableHeader, filteredVendor, loginUser, iscreateTransferLoading]);
}

export default SalesbyQuarterly;