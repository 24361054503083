import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { TagType } from '../markup/components/tagFiles';
import Cookies from "universal-cookie";
import { errorToast } from '../markup/Element/toast';
import { isArray } from 'lodash';

const errorModal = (error, arg) => {
    let errorMessage = "";
    switch (error.status) {
        case 404:
            errorMessage = "Error 404 not found";
            break;
        case 500:
            errorMessage = "Error 500 internal server error";
            break;
        case 401:
            errorMessage = "Error 401 unauthorized";
            break;
        case 400:
            errorMessage = "Error 400 bad request";
            break;
        case 417:
            errorMessage = error.data.value;
            break;
        default:
            errorMessage = `Something went wrong calling ${arg.entity}`;
            break;
    }
    errorToast(errorMessage);
}

const expandChildQuery = (expand) => {
    return (
        "$expand=" +
        Object.entries(expand)
            .map(
                (o) => `${o[0]}
            ${o[1].fields
                        ? `($filter=IsDeleted ne true;${o[1].top ? `$top=${o[1].top};` : ""
                        }$select=${o[1].fields};${o[1].expand
                            ? expandChildQuery(o[1].expand)
                            : o[1].filter
                                ? `($filter=${o[1].filter})`
                                : ""
                        })`
                        : `${o[1].expand
                            ? `($filter=IsDeleted ne true;${o[1].top ? `$top=${o[1].top};` : ""
                            }${expandChildQuery(o[1].expand)})`
                            : o[1].filter
                                ? `($filter=${o[1].filter})`
                                : ""
                        }`
                    }`
            )
            .join(",")
            .trim()
    );
};

const entitiesChildQuery = (entities) => {
    return Object.entries(entities).map(
        (o) => {
            return `entities=${o[0]}`
        }
    ).join("&").trim();
};

const baseAPIQuery = ({
    entity,
    subEntity = "",
    filter = "",
    extraFilterQuery = "",
    sort = [],
    skip = 0,
    top = 10,
    expand = {},
    entities = {},
    query = "",
    afterQueryWithAllRecords = "",
    count = false,
    globalFilter = {},
}, allRecords) => {
    let finalUrl = `${entity}`;
    let filterQuery = entity.includes("Shopify") ? "$filter=Id ne null" : "$filter=IsDeleted ne true";
    if (subEntity !== "") {
        finalUrl += `/${subEntity}`;
    }
    if (Object.entries(globalFilter).length > 0) {
        if (
            globalFilter.globalSearchKeyword &&
            globalFilter.globalSearchKeyword !== ""
        ) {
            let filterData = [];
            globalFilter.columns.forEach((value) => {
                if (
                    value.Header !== "Action" &&
                    value.Header !== "Media" &&
                    typeof value.accessor !== "function" &&
                    value.type !== "Boolean"
                ) {
                    if (value.type !== "Number") {
                        filterData.push(
                            `contains(tolower(${value.accessor.replaceAll('.', '/')}), tolower('${globalFilter.globalSearchKeyword}'))`
                        );
                    } else if (!isNaN(globalFilter.globalSearchKeyword)) {
                        filterData.push(
                            `${value.accessor} eq ${globalFilter.globalSearchKeyword}`
                        );
                    }
                }
            });
            filterQuery += ` and (${filterData.join(" or ")})`;
        }
    }
    if (filter !== "") {
        filterQuery += ` and (${filter})`;
    }
    if (extraFilterQuery !== "") {
        filterQuery += ` and (${extraFilterQuery})`;
    }
    finalUrl += allRecords ? '' : `?$skip=${skip}&$top=${top}&$count=true`;
    if (query !== "") {
        finalUrl = `${finalUrl}&${query}`;
    }
    if (sort.length > 0) {
        let sortQuery = allRecords ? "?orderby=" : "&$orderby=";
        sortQuery += sort.map((value) => {
            return `${value.columnName} ${value.direction}`;
        });
        finalUrl += sortQuery;
    } else {
        finalUrl += allRecords ? `?$orderby=Id desc ${count ? "&$count=true" : ''}` : "&$orderby=Id desc";
    }
    if (afterQueryWithAllRecords !== "") {
        finalUrl = `${finalUrl}&${afterQueryWithAllRecords}`;
    }
    if (Object.entries(expand).length > 0) {
        let expandQueryStr = "";
        expandQueryStr = "&" + expandChildQuery(expand);
        finalUrl += expandQueryStr;
        Object.entries(expand).forEach((o) => {
            if (!o[1].expand && !o[1].filter) {
                filterQuery += ` and ${o[0]} /IsDeleted ne true`;
            }
        });
    }
    if (Object.entries(entities).length > 0) {
        let entitiesQueryStr = "";
        entitiesQueryStr = "&" + entitiesChildQuery(entities);
        finalUrl += entitiesQueryStr;
    }
    finalUrl += `&${filterQuery}`;
    return finalUrl
};

export const pcApi = createApi({
    reducerPath: 'pcApi',
    tagTypes: TagType,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL
    }),
    endpoints: (builder) => ({
        getRecords: builder.query({
            query: ({ entity, expand = "", columnFilter = "" }) => {
                var filterUrlString = "";
                var filterForAllColumns = { ...columnFilter }
                if (columnFilter && columnFilter !== "") {
                    filterUrlString = Object.entries(filterForAllColumns)
                        .map(([key, value]) => {
                            if (isArray(value)) {
                                var urlString = ' AND (';
                                value.map((item, index) => {
                                    index === 0 ?
                                        urlString += `${key} eq '${item}'`
                                        : urlString += ` OR ${key} eq '${item}'`
                                }
                                );
                                urlString += ")";
                                return (urlString)
                            }
                            if (typeof (value) === 'string') {
                                return (` AND contains(${key},'${value}')`)
                            }
                            return (` ${key} eq ${value}`)
                        })
                }
                if (expand !== "") {
                    filterUrlString += `&$expand=${expand}`
                }
                var newUrl = "";

                newUrl = `${entity}${filterUrlString}`
                var newUrl1 = `${entity}?$filter=IsDeleted ne true AND ${filterUrlString}`
                let cookies = new Cookies()
                let accessToken = cookies.get("accessToken");
                return {
                    url: columnFilter !== "" ? newUrl1 : `${newUrl}`,
                    method: 'GET',
                    headers: {
                        'content-type': `application/json;odata=verbose`,
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
        getAllRecords: builder.query({
            query: (params) => {
                const finalUrl = baseAPIQuery(params, params?.allRecords ?? false);
                let cookies = new Cookies()
                let accessToken = cookies.get("accessToken");
                return {
                    url: `${finalUrl}`,
                    method: 'GET',
                    headers: {
                        'content-type': `application/json;odata=verbose`,
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    errorModal(error, arg);
                }
                return (arg.tag ? [arg.tag] : [])
            },
        }),
        getSimpleRecords: builder.query({
            query: ({ entity }) => {
                let cookies = new Cookies()
                let accessToken = cookies.get("accessToken");
                return {
                    url: `${entity}`,
                    method: "GET",
                    headers: {
                        'content-type': `application/json;odata=verbose`,
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
        getRecordsById: builder.query({
            query: ({ entity, id, entities }) => {
                let finalUrl = `${entity}/${id}`;
                if (entities && Object.entries(entities)?.length > 0) {
                    let entitiesQueryStr = "";
                    entitiesQueryStr = "?" + entitiesChildQuery(entities);
                    finalUrl += entitiesQueryStr;
                }
                let cookies = new Cookies()
                let accessToken = cookies.get("accessToken");
                return {
                    url: `${finalUrl}`,
                    method: "GET",
                    headers: {
                        "content-type": `application/json;odata=verbose`,
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
        add: builder.mutation({
            query: ({ entity, query = "", data }) => {
                let cookies = new Cookies()
                let accessToken = cookies.get("accessToken");
                return {
                    url: entity,
                    method: "POST",
                    body: data,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        "Cache-Control": "no-cache",
                        Accept: "application/json",
                    },
                    params: query,
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (error) {
                    errorModal(error, arg);
                }
                return arg.tag
                    ? typeof arg.tag === "object"
                        ? [...arg.tag]
                        : [arg.tag]
                    : [];
            },
        }),
        update: builder.mutation({
            query: ({ entity, data, query = "", Id = 0 }) => {
                let cookies = new Cookies();
                let accessToken = cookies.get("accessToken");
                return {
                    url: `${entity}${Id ? `/${Id ? Id : data.Id}` : ""}${query ? `?${query}` : ""
                        }`,
                    method: "PUT",
                    body: data,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        "Cache-Control": "no-cache",
                        Accept: "application/json",
                    },
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (error) {
                    errorModal(error, arg);
                }
                return arg.tag
                    ? typeof arg.tag === "object"
                        ? [...arg.tag]
                        : [arg.tag]
                    : [];
            },
        }),
        delete: builder.mutation({
            query: ({ entity, id }) => {
                let cookies = new Cookies();
                let accessToken = cookies.get("accessToken");
                return {
                    url: `${entity}/${id}`,
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (error) {
                    errorModal(error, arg);
                }
                return (arg.tag ? [arg.tag] : [])
            },
        }),
    }),
})

export const {
    useLazyGetRecordsQuery,
    useGetRecordsQuery,
    useGetRecordsByIdQuery,
    useLazyGetSimpleRecordsQuery,
    useLazyGetRecordsByIdQuery,
    useGetAllRecordsQuery,
    useLazyGetAllRecordsQuery,
    useAddMutation,
    useUpdateMutation,
    useDeleteMutation
} = pcApi
