import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useLiveQuery } from 'dexie-react-hooks';
import { CircularProgress } from '@material-ui/core';
import { SHOPIFY_TRANSFERS } from '../../shared/tagFiles';
import { useLazyGetAllRecordsQuery } from '../../services/pcApi';
import ReactTable from '../../shared/components/Table';
import { db } from './ProductScan/Dexie/db';

const Transfer = () => {
	const history = useHistory()
	const [loading, setLoading] = useState(false);
	const [getAllTransferRecords] = useLazyGetAllRecordsQuery();

	const handleShowDetails = (transfer) => {
		history.push(`/transfer/${transfer.Id}`, {
			sku: transfer,
		})
	}

	const transfers = useLiveQuery(
		async () => {
			const Transfers = await db.Transfers.toArray();
			return Transfers;
		}
	);

	const columns = [
		{
			Header: "Id",
			accessor: "Id",
			type: "Number",
		},
		{
			Header: "Origin",
			accessor: "OriginLocation.Name",
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "Destination",
			accessor: "DestinationLocation.Name",
			Cell: ({ value }) => {
				return value ? value : "-";
			},
		},
		{
			Header: "Status",
			accessor: "Status.Name",
		},
	];

	const onRefresh = () => {
		setLoading(true)
		getAllTransferRecords({
			entity: `ShopifyReference/ShopifyTransfers`,
			entities: { OriginLocation: {}, DestinationLocation: {}, Status: {} },
			count: true,
			tag: SHOPIFY_TRANSFERS,
			allRecords: true,
		}).then((response) => {
			if (!response.error) {
				db.Transfers.clear()
				let time = new Date()
				response?.data?.value?.forEach((record, i) => {
					db.Transfers.add({
						Id: i + 1,
						SkuObject: record.SkuObject,
						OriginLocation: record.OriginLocationDTO,
						DestinationLocation: record.DestinationLocationDTO,
						Status: record.StatusDTO,
						CreatedAt: record.CreatedAt,
						UpdatedAt: record.UpdatedAt,
						lastSynced: time,
					});
				});
			}
			setLoading(false)
		})
	}

	return (
		<>
			<div className='py-3'>
				<div className='container bg-white p-3' style={{ borderRadius: "10px" }}>
					<div className='p-3' style={{ borderRadius: "10px", border: "1px solid rgba(0, 0, 0, .125)" }}>
						<div className="card__title d-flex justify-content-between align-items-center mb-2"><h5 className="bold-text"><i className="fa fa-arrow-left mr-2" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => history.goBack()}></i>Transfers</h5>
							<Button onClick={onRefresh} disabled={loading}>
								{loading && <CircularProgress color="inherit" size={18} className='mr-1' />}
								Refresh
							</Button>
						</div>
						<div>
							{transfers?.length &&
								<ReactTable columns={columns} data={transfers?.length ? transfers : []} isLoading={loading} hasHandleShow handleShowDetails={handleShowDetails} />
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Transfer