//Auth
export const USER_TAG = "user_tag";

export const SHOPIFY_LOCATIONS = "shopify_locations";
export const SHOPIFY_PRODUCT = "shopify_product";
export const BACKERKIT_PRODUCT = "backerkit_product";
export const SHOPIFY_QUANTITY_LOCATIONS = "shopify_quantity_product";
export const SHOPIFY_ORDER_TAG = "shopify_order_tag";
export const TRANSFER_DATA = "transfer_data";
export const RECEIVE_DATA = "receive_data";
export const SHOPIFY_TRANSFERS = "shopify_transfer";
export const ASK_ROLE_TAG = "ask_role_tag";
export const SURVEY_TAG = "survey_tag";

export const TagType = [
    //Auth
    USER_TAG,
    SHOPIFY_LOCATIONS,
    SHOPIFY_PRODUCT,
    BACKERKIT_PRODUCT,
    SHOPIFY_QUANTITY_LOCATIONS,
    SHOPIFY_ORDER_TAG,
    TRANSFER_DATA,
    RECEIVE_DATA,
    SHOPIFY_TRANSFERS,
    ASK_ROLE_TAG,
    SURVEY_TAG
];